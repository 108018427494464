import { ReactNode } from 'react';
import MediaQuery from 'react-responsive';

type MediaType = {
  children: ReactNode;
};

export const screenSizes = {
  desktop: 1920,
  tablets: 1366,
  olderTablets: 1024,
  mobile: 768,
};
export const lessThenTabletWidth = `@media (max-width: ${screenSizes.tablets}px)`;

export let LargeDesktops = (props: MediaType) => <MediaQuery {...props} minWidth={screenSizes.desktop + 1} />;
export let DesktopAndLaptops = (props: MediaType) => (
  <MediaQuery {...props} minWidth={screenSizes.tablets + 1} maxWidth={screenSizes.desktop} />
);
export let Tablets = (props: MediaType) => (
  <MediaQuery {...props} minWidth={screenSizes.olderTablets + 1} maxWidth={screenSizes.tablets} />
);
export let OlderTablets = (props: MediaType) => (
  <MediaQuery {...props} minWidth={screenSizes.mobile + 1} maxWidth={screenSizes.olderTablets} />
);
export let Mobile = (props: MediaType) => <MediaQuery {...props} maxWidth={screenSizes.mobile} />;

export let LargerThenMobile = (props: MediaType) => <MediaQuery {...props} minWidth={screenSizes.mobile + 1} />;
export let SmallerThenTablet = (props: MediaType) => <MediaQuery {...props} maxWidth={screenSizes.tablets} />;
export let LargerThenTablet = (props: MediaType) => <MediaQuery {...props} minWidth={screenSizes.tablets} />;
