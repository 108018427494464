import Drawer from '@material-ui/core/Drawer';
import { ComponentType, FC } from 'react';
import AccountItems from './presentational/AccountItems';

type Props = {
  accountDrawerState: boolean;
  toggleDrawer: (state: boolean) => () => any;
  classes?: {
    accountDrawer: string;
    header: string;
  };
  render?: ComponentType;
};

const AccountDrawer: FC<Props> = (props) => {
  const closeActionsContent = props.toggleDrawer(false);

  return (
    <Drawer
      anchor="right"
      open={props.accountDrawerState}
      onClose={closeActionsContent}
      data-test-type="account-drawer"
    >
      <div tabIndex={0} role="button" onKeyDown={closeActionsContent}>
        <AccountItems {...props} />
      </div>
    </Drawer>
  );
};

export default AccountDrawer;
