import numberFormatter from 'global-utils/numberFormatter/numberFormatter';
import { FC } from 'react';
import { UNIT_CO2 } from '../../../../utils/constants';

type Props = { value?: number | null };
const AmountCO2: FC<Props> = ({ value }) =>
  value ? (
    <>
      {numberFormatter.format(value)} {UNIT_CO2}
    </>
  ) : null;

export default AmountCO2;
