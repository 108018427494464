import { lazy } from 'react';

const componentLoader = async <T = any>(lazyComponent: () => Promise<T>, attemptsLeft: number = 3): Promise<T> => {
  try {
    return await lazyComponent();
  } catch (e) {
    if (attemptsLeft === 0) {
      throw e;
    }
    await new Promise((resolve) => setTimeout(resolve, 500));
    return componentLoader(lazyComponent, attemptsLeft - 1);
  }
};

/**
 * This loader prevents reacts — Loading Chunk Failed Error
 * @see https://medium.com/@botfather/react-loading-chunk-failed-error-88d0bb75b406
 * @param factory the component to load
 */
const lazyRetry: typeof lazy = (factory) => lazy(() => componentLoader(factory));

export default lazyRetry;
