import { useQuery } from '@apollo/client';
import { UnitsRootNode } from 'global-screens/Units/graphql/__generated__/UnitsRootNode';
import { queries } from 'global-screens/Units/graphql/queries';
import { Dispatch, StoreState } from 'global-store/store';
import nodeTypes from 'global-utils/node-types/node-types';
import { Fragment, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AccountDrawerItem from '../AccountDrawerItem/AccountDrawerItem';
import SupportItem from './components/SupportItem/SupportItem';

export const AccountDrawerItems: FunctionComponent = () => {
  const { data } = useQuery<UnitsRootNode>(queries.rootNode);
  const dispatch = useDispatch<Dispatch>();
  const isCompanyUser = useSelector((state: StoreState) => state.user.companyNodeId) !== undefined;

  const { t } = useTranslation([
    'unit-details-screen',
    'subscription-statistics',
    'import-export-screen',
    'users-screen',
    'measurand-whitelist',
    'credential-screen',
  ]);

  const showImportExportScreen = dispatch.importExportDialog.openImportExportScreen;
  const showCredentialScreen = () => dispatch.credentialManagementDialog.showManagementScreen(true);
  const showUsersScreen = () =>
    dispatch.usersDialog.handleUserActionsDialogState({ dialogState: true, companyId: data?.root.id ?? null });
  const showSubscriptionStatistics = dispatch.subscriptionStatistics.showSubscriptionStatistics;
  const showMeasurandWhitelistDialog = () =>
    dispatch.measurandWhitelistDialog.handleMeasurandWhitelistDialogState(true);
  const showUserRootNodeSettings = () => {
    if (data && data.root) {
      dispatch.unitDialog.handleUnitDialogState({ dialogState: true, id: data.root.id });
    }
  };
  let settingTitle: string | null = null;
  if (data) {
    switch (data.root.type.mainType) {
      case nodeTypes.company:
        settingTitle = t('unit-details-screen:company-settings-item');
        break;
      case nodeTypes.reseller:
        settingTitle = t('unit-details-screen:reseller-settings-item');
        break;
      case nodeTypes.root:
        settingTitle = t('unit-details-screen:root-settings-item');
        break;
    }
  }

  return (
    <Fragment>
      {settingTitle && (
        <AccountDrawerItem data-test-type="account-drawer-unit-details-screen" onClick={showUserRootNodeSettings}>
          {settingTitle}
        </AccountDrawerItem>
      )}

      {isCompanyUser && (
        <AccountDrawerItem
          data-test-type="account-drawer-measurand-whitelist-screen"
          onClick={showMeasurandWhitelistDialog}
          check="canWhitelistMeasurands"
        >
          {t('measurand-whitelist:measurand-whitelist-item')}
        </AccountDrawerItem>
      )}

      <AccountDrawerItem
        data-test-type="account-drawer-import-export-screen"
        onClick={showImportExportScreen}
        check={['canImportTimeSeries', 'canExportTimeSeries', 'canImportStructure', 'canExportStructure']}
      >
        {t('import-export-screen:import-export-item')}
      </AccountDrawerItem>

      {data?.root.type.mainType !== nodeTypes.reseller && (
        <AccountDrawerItem
          data-test-type="account-drawer-credential-management-screen"
          onClick={showCredentialScreen}
          check={'canManageCredentials'}
        >
          {t('credential-screen:credential-item')}
        </AccountDrawerItem>
      )}

      <AccountDrawerItem
        data-test-type="account-drawer-subscription-statistics-screen"
        onClick={showSubscriptionStatistics}
        check="canReadSubscriptions"
      >
        {t('subscription-statistics:subscription-statistics-item')}
      </AccountDrawerItem>

      {data?.root.type.mainType === nodeTypes.company && (
        <AccountDrawerItem
          data-test-type="account-drawer-users-screen"
          onClick={showUsersScreen}
          check="canManageUsers"
        >
          {t('users-screen:title')}
        </AccountDrawerItem>
      )}
      <SupportItem />
    </Fragment>
  );
};

export default AccountDrawerItems;
