import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ApplicationSubscriptionExpiryHint from 'global-components/containers/ApplicationSubscriptionExpiryHint/ApplicationSubscriptionExpiryHint';
import Header from 'global-components/containers/Header/Header';
import ProtectedRoute from 'global-components/containers/ProtectedRoute/ProtectedRoute';
import HasPermission from 'global-components/presentational/HasPermission/HasPermission';
import Compensation from 'global-screens/Compensation/Compensation';
import saveImportExportScreenInUrl from 'global-screens/ImportExport/utils/saveImportExportScreenInUrl/saveImportExportScreenInUrl';
import LoadingScreenPlaceholder from 'global-screens/LoadingScreenPlaceholder/LoadingScreenPlaceholder';
import NoMatch from 'global-screens/NoMatch/NoMatch';
import saveSubscriptionStatisticsInUrl from 'global-screens/SubscriptionStatistics/utils/saveSubscriptionStatisticsScreenInUrl/saveSubscriptionStatisticsInUrl';
import saveTariffDetailsScreenInUrl from 'global-screens/TariffDetails/utils/saveTariffDetailsScreenInUrl';
import saveUnitDetailsScreenInUrl from 'global-screens/UnitDetails/utils/saveUnitDetailsScreenStateInUrl/saveUnitDetailsScreenInUrl';
import saveUsersScreenInUrl from 'global-screens/Users/utils/saveUsersScreenInUrl/saveUsersScreenInUrl';
import { StoreState } from 'global-store/store';
import lazyRetry from 'global-utils/lazyRetry/lazyRetry';
import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

const Dashboard = lazyRetry(() => import('global-screens/Dashboard/Dashboard'));
const GraphiQL = lazyRetry(() => import('global-screens/GraphiQL/GraphiQL'));
const Reports = lazyRetry(() => import('global-screens/Reports/Reports'));
const Measurements = lazyRetry(() => import('global-screens/Measurements/Measurements'));
const UnitsList = lazyRetry(() => import('global-screens/Units/Units'));
const Monitoring = lazyRetry(() => import('global-screens/Monitoring/Monitoring'));
const Tariffs = lazyRetry(() => import('global-screens/Tariffs/Tariffs'));

const UnitDetails = saveUnitDetailsScreenInUrl(lazyRetry(() => import('global-screens/UnitDetails/UnitDetails')));
const TariffDetails = saveTariffDetailsScreenInUrl(
  lazyRetry(() => import('global-screens/TariffDetails/TariffDetails'))
);
const ImportExport = saveImportExportScreenInUrl(lazyRetry(() => import('global-screens/ImportExport/ImportExport')));
const Users = saveUsersScreenInUrl(lazyRetry(() => import('global-screens/Users/Users')));
const MeasurandWhitelist = lazyRetry(() => import('global-screens/MeasurandWhitelist/MeasurandWhitelist'));
const CredentialManagement = lazyRetry(() => import('global-screens/CredentialManagement/CredentialManagement'));
const SubscriptionStatistics = saveSubscriptionStatisticsInUrl(
  lazyRetry(() => import('global-screens/SubscriptionStatistics/SubscriptionStatistics'))
);

const useStyles = makeStyles({
  app: {
    padding: '0 12px',
    height: '100%',
  },
  header: {
    marginBottom: 40,
  },
  body: {
    padding: '0 40px',
    height: 'calc(100% - 116px)',
  },
  gridContainer: {
    height: '100%',
  },
});

const measurementsScreenPath = [
  '/measurements/:selectedNode/from/:startDate/to/:endDate',
  '/measurements/:selectedNode?',
];
const compensationScreenPath = [
  '/compensation/:selectedNode/from/:startDate/to/:endDate',
  '/compensation/:selectedNode?',
];

export const App = () => {
  const accessToken = useSelector((state: StoreState) => state.user.accessToken);
  const classes = useStyles();

  return (
    <div className={classes.app}>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={6} className={classes.header}>
          <Header />
        </Grid>

        <Grid container spacing={0} className={classes.body}>
          <Switch>
            <Suspense fallback={<LoadingScreenPlaceholder />}>
              <ProtectedRoute exact path="/" component={Dashboard} accessToken={accessToken} />
              <ProtectedRoute path="/dashboard/:id?" component={Dashboard} accessToken={accessToken} />
              <ProtectedRoute path="/reports/:year?" component={Reports} accessToken={accessToken} />
              <ProtectedRoute path={measurementsScreenPath} component={Measurements} accessToken={accessToken} />
              <ProtectedRoute path="/units/:id?" component={UnitsList} accessToken={accessToken} />
              <ProtectedRoute path={compensationScreenPath} component={Compensation} accessToken={accessToken} />
              <ProtectedRoute path="/monitoring/:jobId?" component={Monitoring} accessToken={accessToken} />
              <ProtectedRoute path="/tariffs/:tariffId?" component={Tariffs} accessToken={accessToken} />

              <ProtectedRoute path="/graphiql" component={GraphiQL} accessToken={accessToken} />
            </Suspense>

            <Route component={NoMatch} />
          </Switch>
        </Grid>
      </Grid>

      {accessToken && (
        <Suspense fallback={null}>
          <UnitDetails />
          <TariffDetails />

          <HasPermission check="canReadSubscriptions">
            <SubscriptionStatistics />
          </HasPermission>

          <HasPermission
            check={['canImportTimeSeries', 'canExportTimeSeries', 'canImportStructure', 'canExportStructure']}
          >
            <ImportExport />
          </HasPermission>

          <HasPermission check="canManageUsers">
            <Users />
          </HasPermission>

          <ApplicationSubscriptionExpiryHint />

          <HasPermission check="canWhitelistMeasurands">
            <MeasurandWhitelist />
          </HasPermission>

          <HasPermission check="canManageCredentials">
            <CredentialManagement />
          </HasPermission>
        </Suspense>
      )}
    </div>
  );
};

export default App;
