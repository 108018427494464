import ExpansionPanel from '@material-ui/core/ExpansionPanel/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import { Notifications_notifications } from 'global-graphql/__generated__/Notifications';
import { NotificationTemplates_notificationTemplates } from 'global-graphql/__generated__/NotificationTemplates';
import { Theme } from 'global-utils/uiThemeConfig';
import { FC } from 'react';
import { MessageLevel } from '../../../../../../../../../__generated__/globalTypes';
import NotificationItem from './components/NotificationItem/NotificationItem';

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    width: '100%',
  },
  expansionPanel: {
    padding: 0,
  },
  info: {
    color: theme.palette.info.light,
  },
  warning: {
    color: theme.palette.warning.light,
  },
  error: {
    color: theme.palette.error.light,
  },
  groupIcon: {
    marginRight: theme.spacing(1),
  },
  counterText: {
    color: theme.palette.text.secondary,
  },
}));

type Props = {
  template: NotificationTemplates_notificationTemplates;
  notifications: Notifications_notifications[];
};

const NotificationTemplateGroup: FC<Props> = ({ template, notifications }) => {
  const classes = useStyles();
  if (notifications.length === 0) {
    return null;
  }
  let Icon;
  let className;
  switch (template.level) {
    case MessageLevel.ERROR:
      Icon = ErrorIcon;
      className = classes.error;
      break;
    case MessageLevel.INFO:
      Icon = InfoIcon;
      className = classes.info;
      break;
    case MessageLevel.WARNING:
      Icon = WarningIcon;
      className = classes.warning;
      break;
    default:
      return null;
  }
  return (
    <ExpansionPanel TransitionProps={{ timeout: 0, unmountOnExit: true }} data-test-type="notification-template">
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${template.id}-content`}
        id={`${template.id}-header`}
        data-test-type="notification-template-header"
      >
        <Icon className={classes.groupIcon + ' ' + className} />
        <Typography>
          {template.summary} <span className={classes.counterText}>({notifications.length})</span>
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.expansionPanel} data-test-type="notification-template-body">
        <List classes={{ root: classes.list }} dense>
          {notifications.map((notification) => (
            <NotificationItem key={notification.id} template={template} notification={notification} />
          ))}
        </List>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default NotificationTemplateGroup;
