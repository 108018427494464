const nodeTypes = {
  meter: 'common:node-type:meter',
  company: 'common:node-type:company',
  reseller: 'common:node-type:reseller',
  location: 'common:node-type:location',
  unit: 'common:node-type:unit',
  root: 'common:node-type:root',
};

export default nodeTypes;
