import Chip from '@material-ui/core/Chip';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CompensationOrderStatus } from '../../../../../../__generated__/globalTypes';

type Props = {
  status: CompensationOrderStatus;
};
const OrderStatus: FC<Props> = ({ status }) => {
  const { t } = useTranslation('compensation');
  return (
    <Chip
      label={t(`compensation:status.${status}`)}
      variant="outlined"
      size="small"
      color={status === CompensationOrderStatus.COMPLETED ? 'secondary' : 'default'}
    />
  );
};

export default OrderStatus;
