import { useQuery } from '@apollo/client';
import { AllWidgets } from 'global-graphql/__generated__/AllWidgets';
import { queries } from 'global-graphql/queries';
import { useCallback } from 'react';

const useGetWidget = () => {
  const { data: allWidgets } = useQuery<AllWidgets>(queries.allWidgets, { fetchPolicy: 'cache-first' });
  return useCallback((widgetId: string) => allWidgets?.widgets?.find((widget) => widget.id === widgetId) ?? null, [
    allWidgets,
  ]);
};

export default useGetWidget;
