import { useQuery } from '@apollo/client';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GetCompensationOrderLogs,
  GetCompensationOrderLogsVariables,
} from '../../../../graphql/__generated__/GetCompensationOrderLogs';
import { queries } from '../../../../graphql/queries';
import { TIME_FORMAT } from '../../../../utils/constants';
import OrderStatus from '../OrderStatus/OrderStatus';

type Props = {
  orderId: string;
  onClose: () => void;
};

const CompensationOrderLogs: FC<Props> = ({ orderId, onClose }) => {
  const { data, loading, error } = useQuery<GetCompensationOrderLogs, GetCompensationOrderLogsVariables>(
    queries.getCompensationOrderLogs,
    { variables: { orderId } }
  );
  const { t } = useTranslation(['compensation', 'global']);

  if (loading || error || !data?.compensationOrderLogs) {
    return null;
  }
  return (
    <Dialog open={true} onClose={onClose} maxWidth="lg" aria-labelledby={'log-dialog-title'}>
      <DialogTitle id="log-dialog-title">{t(`compensation:logs.headline`)}</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t(`compensation:logs.timestamp`)}</TableCell>
                <TableCell>{t(`compensation:logs.status`)}</TableCell>
                <TableCell>{t(`compensation:logs.message`)}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.compensationOrderLogs.map((log, index) => (
                <TableRow key={index}>
                  <TableCell>{moment(log.time).format(TIME_FORMAT)}</TableCell>
                  <TableCell>
                    <OrderStatus status={log.status} />
                  </TableCell>
                  <TableCell>{log.message}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="default">
          {t(`global:confirmation-button-confirm`)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompensationOrderLogs;
