import { PermissionChecker } from '@ence/permission-facade';
import { createModel } from '@rematch/core';
import { store } from 'global-store/store';
import Keycloak from 'keycloak-js/dist/keycloak';
import { RootModel } from '../models';

export type UserProfile = Keycloak.KeycloakProfile;
export type Login = () => void;
export type Logout = () => void;

export type State = {
  login: Login;
  logout: Logout;
  accessToken: string;
  companyNodeId?: string;
  userProfile: UserProfile;
  accountSettingsURL?: string;
  sTAM: boolean;
  permissionChecker: ReturnType<typeof PermissionChecker>;
};

type KeycloakLoadedActionArgs = {
  token?: string;
  login: Login;
  logout: Logout;
  companyNodeId: string;
  sTAM: boolean;
  permissionChecker: ReturnType<typeof PermissionChecker>;
  userProfile: Keycloak.KeycloakProfile;
  accountSettingsURL: string;
};

const dummyMethod = () => {};

const initialState: State = {
  logout: dummyMethod,
  login: dummyMethod,
  accessToken: '',
  userProfile: {},
  sTAM: false,
  permissionChecker: PermissionChecker(() => false),
};

let keyCloakInstancePromise: Promise<Keycloak.KeycloakInstance>;

const initialized = () => {
  if (!keyCloakInstancePromise) {
    keyCloakInstancePromise =
      window['ce1KeycloakInstance'] ||
      new Promise<Keycloak.KeycloakInstance>(async (resolve, reject) => {
        // window['GRAPHQL_URL'] comes from the version 1, process.env.REACT_APP_CLIENT_URL is used in development and the relative path /environment in production
        const response = await fetch(`${window['GRAPHQL_URL'] || process.env.REACT_APP_CLIENT_URL || ''}/environment`, {
          mode: 'cors',
        });
        const environment = await response.json();

        store.dispatch.environment.setEnvironment(environment);

        const keyCloak = Keycloak({
          ...environment.keycloak,
        });

        keyCloak
          .init({
            onLoad: 'login-required',
            checkLoginIframe: false,
          })
          .then(() => resolve(keyCloak))
          .catch((err) => reject(err));
      });
  }

  return keyCloakInstancePromise;
};

export const user = createModel<RootModel>()({
  state: { ...initialState } as State,

  reducers: {
    keycloakLoadedAction: (state, payload: KeycloakLoadedActionArgs) => ({
      ...state,
      logout: payload.logout,
      login: payload.login,
      userProfile: payload.userProfile,
      accountSettingsURL: payload.accountSettingsURL,
      accessToken: payload.token ?? '',
      companyNodeId: payload.companyNodeId,
      sTAM: payload.sTAM,
      permissionChecker: payload.permissionChecker,
    }),
    tokenUpdatedAction: (state, accessToken: string) => ({
      ...state,
      accessToken,
    }),
  },

  effects: (dispatch) => {
    return {
      async fetchUser() {
        const keyCloak = await initialized();

        try {
          const userProfile = await keyCloak.loadUserProfile();
          const id = userProfile.id ?? (await keyCloak.loadUserInfo())['sub'];

          /**
           * Store the KeyCloak methods and user's profile for further application
           */
          dispatch.user.keycloakLoadedAction({
            logout: keyCloak.logout,
            login: keyCloak.login,
            token: keyCloak.token,
            sTAM: keyCloak.hasRealmRole('sTAM'),
            companyNodeId: keyCloak.tokenParsed && (keyCloak.tokenParsed as any).companyNodeId,
            permissionChecker: PermissionChecker(keyCloak.hasRealmRole),
            userProfile: {
              ...userProfile,
              id,
            },
            accountSettingsURL: `${keyCloak.authServerUrl}/realms/${keyCloak.realm}/account`,
          });
        } catch (e) {
          keyCloak.login();
        }
      },
      async updateAccessToken() {
        const keyCloak = await initialized();

        try {
          await keyCloak.updateToken(10);

          if (keyCloak.token) {
            dispatch.user.tokenUpdatedAction(keyCloak.token);
          }

          return keyCloak.token;
        } catch (e) {
          // refreshing the token failed
          console.error(e);

          // redirect the user to the keycloak's login page
          keyCloak.login();

          // reject the promise to inform other parts of the application that the refresh failed
          throw e;
        }
      },
    };
  },
});
