import { FC } from 'react';
import Widget from 'global-components/presentational/Widget/Widget';

type Props = {
  startDate: string;
  endDate: string;
  setDateRange: (dateRange: { startDate: string; endDate: string }) => void;
};

const DateRangeSelection: FC<Props> = ({ startDate, endDate, setDateRange }) => (
  <Widget
    widgetId="common:widget:date-range-selection"
    height={80}
    config={{ dateRange: { startDate, endDate } }}
    onConfigurationChange={setDateRange}
  />
);

export default DateRangeSelection;
