import Widget from 'global-components/presentational/Widget/Widget';
import { FC } from 'react';

export type DataPoint = {
  nodeId: string;
  measurand: string;
};
export type SingleDataPointSelectionProps = {
  setSelectedDatapoint: (dataPoint: DataPoint) => void;
  selectedDataPoint?: DataPoint | null;
  skipAccounting?: boolean;
  skipAutomated?: boolean;
  skipManual?: boolean;
};

const SingleDataPointSelection: FC<SingleDataPointSelectionProps> = ({
  setSelectedDatapoint,
  selectedDataPoint: dataPoint,
  skipAccounting,
  skipManual,
  skipAutomated,
}) => {
  return (
    <Widget
      widgetId="common:widget:single-data-point-selection"
      height={48}
      config={{
        dataPoint,
        skipAccounting,
        skipManual,
        skipAutomated,
      }}
      onConfigurationChange={setSelectedDatapoint}
      emphasiseErrors={true}
    />
  );
};

export default SingleDataPointSelection;
