import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { possibleTypes } from '@ence/apis-graphql/fragment-types.json';
import { createUploadLink } from 'apollo-upload-client';
import { Dispatch } from 'global-store/store';
import fetch from 'global-utils/fetch/fetch';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

const AuthenticationProvider: FC = ({ children }) => {
  const dispatch = useDispatch<Dispatch>();
  useEffect(() => {
    dispatch.user.fetchUser();
  }, [dispatch]);

  const client = new ApolloClient({
    link: createUploadLink({
      // process.env.REACT_APP_CLIENT_URL is used in development and
      // the relative path /graphql in production
      uri: `${process.env.REACT_APP_CLIENT_URL || ''}/graphql`,
      fetch,
    }),
    cache: new InMemoryCache({ possibleTypes }),
  });
  return <ApolloProvider client={client} children={children} />;
};

export default AuthenticationProvider;
