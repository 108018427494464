import { Replacements } from 'global-graphql/__generated__/Replacements';
import moment from 'moment';
import { FC } from 'react';
import BalanceVisualizationLink from './components/BalanceVisualizationLink/BalanceVisualizationLink';
import CompensationDetailsLink from './components/CompensationDetailsLink/CompensationDetailsLink';
import MeasurementDataLink from './components/MeasurementDataLink/MeasurementDataLink';
import MonitoringJobLink from './components/MonitoringJobLink/MonitoringJobLink';
import NodeDetailsLink from './components/NodeDetailsLink/NodeDetailsLink';
import NodeOverviewLink from './components/NodeOverviewLink/NodeOverviewLink';
import ReportsLink from './components/ReportsLink/ReportsLink';
import SubscriptionLink from './components/SubscriptionLink/SubscriptionLink';
import { AdditionalLinkProps } from './types';

type Props = {
  config: Replacements;
} & AdditionalLinkProps;

const Replacement: FC<Props> = ({ config, ...additionalLinkProps }) => {
  const customLabel = additionalLinkProps.customLabel;
  switch (config.__typename) {
    case 'DateReplacement':
      return <>{customLabel ?? moment(config.date).format('L')}</>;
    case 'DateTimeReplacement':
      return <>{customLabel ?? moment(config.dateTime).format('L LTS')}</>;
    case 'NumberReplacement':
      return (
        <>
          {customLabel ??
            Intl.NumberFormat(undefined, { maximumFractionDigits: config.maximumFractionDigits ?? undefined }).format(
              config.number
            )}
        </>
      );
    case 'StringReplacement':
      return <>{customLabel ?? config.value}</>;
    case 'MeasurementDataLink':
      return <MeasurementDataLink {...additionalLinkProps} {...config} />;
    case 'NodeDetailsLink':
      return <NodeDetailsLink {...additionalLinkProps} {...config} />;
    case 'NodeOverviewLink':
      return <NodeOverviewLink {...additionalLinkProps} {...config} />;
    case 'BalanceVisualizationLink':
      return <BalanceVisualizationLink {...additionalLinkProps} {...config} />;
    case 'SubscriptionLink':
      return <SubscriptionLink {...additionalLinkProps} {...config} />;
    case 'ReportsLink':
      return <ReportsLink {...additionalLinkProps} {...config} />;
    case 'MonitoringJobLink':
      return <MonitoringJobLink {...additionalLinkProps} {...config} />;
    case 'CompensationDetailsLink':
      return <CompensationDetailsLink {...additionalLinkProps} {...config} />;
    default:
      return null;
  }
};

export default Replacement;
