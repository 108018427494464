import { Dispatch, StoreState } from 'global-store/store';
import { Config, UpdateReduxConfig, UpdateUrlConfig } from '../savePopupScreenStateInURL/savePopupScreenStateInURL';

type ExtractedStoreState = { open: boolean };

const simplePopupUrlConfig = (
  urlSearchParamName: string,
  openScreen: (dispatch: Dispatch) => void,
  getOpenState: (state: StoreState) => boolean
): Config<ExtractedStoreState> => {
  const updateReduxOnMount = ({ location, dispatch }: UpdateReduxConfig) => {
    const urlSearchParams = new URLSearchParams(location.search);

    if (urlSearchParams.has(urlSearchParamName)) {
      openScreen(dispatch);
    }
  };

  const extractStateFromRedux = (state: StoreState): ExtractedStoreState => ({ open: getOpenState(state) });

  const updateURLOnReduxChange = ({ state, history, location }: UpdateUrlConfig<ExtractedStoreState>) => {
    const urlSearchParams = new URLSearchParams(location.search);

    if (!state.open && urlSearchParams.has(urlSearchParamName)) {
      urlSearchParams.delete(urlSearchParamName);
    } else if (state.open && !urlSearchParams.has(urlSearchParamName)) {
      urlSearchParams.set(urlSearchParamName, '');
    } else {
      // don't push history unnecessarily often
      return;
    }

    const searchParamsString = urlSearchParams.toString().replace(/=$/, '');

    history.push(location.pathname + (searchParamsString.length ? '?' + searchParamsString : ''));
  };

  return { updateReduxOnMount, extractStateFromRedux, updateURLOnReduxChange };
};

export default simplePopupUrlConfig;
