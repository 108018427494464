import gql from 'graphql-tag';

export type GetImpersonationStatusQueryResult = {
  supportImpersonationStatus: boolean;
};

export const queries = {
  getImpersonationStatus: gql`
    query GetImpersonationStatus {
      supportImpersonationStatus
    }
  `,
};

export const mutations = {
  grantImpersonation: gql`
    mutation GrantImpersonation {
      grantSupportImpersonation
    }
  `,
  revokeImpersonation: gql`
    mutation RevokeImpersonation {
      revokeSupportImpersonation
    }
  `,
};
