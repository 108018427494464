import { createModel } from '@rematch/core';
import { RootModel } from 'global-store/models';

export type ReportDetailsState = {
  dialogState: boolean;
  id: string;
};

export const reportDetails = createModel<RootModel>()({
  state: { dialogState: false, id: '' } as ReportDetailsState,
  reducers: {
    handleReportDialogState: (state, payload: ReportDetailsState) => ({ ...state, ...payload }),
  },
});
