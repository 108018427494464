import { useQuery } from '@apollo/client';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import ResolvedUnitNameLink from 'global-components/containers/ResolvedMessage/components/Replacement/components/ResolvedUnitNameLink/ResolvedUnitNameLink';
import HasPermission from 'global-components/presentational/HasPermission/HasPermission';
import moment from 'moment';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { GetCompensationOrders } from '../../graphql/__generated__/GetCompensationOrders';
import { queries } from '../../graphql/queries';
import { TIME_FORMAT, UNIT_CO2 } from '../../utils/constants';
import AmountCO2 from '../CompensationItems/presentational/AmountCO2/AmountCO2';

import Price from '../CompensationItems/presentational/Price/Price';
import CompensationOrderLogs from './presentational/CompensationOrderLogs/CompensationOrderLogs';
import OrderStatus from './presentational/OrderStatus/OrderStatus';

const useStyles = makeStyles({
  row: {
    cursor: 'pointer',
  },
});

const CompensationLink: FC<{
  nodeId: string;
  start: any;
  end: any;
}> = ({ nodeId, start, end }) => {
  const history = useHistory();
  const link = `/compensation/${nodeId}/from/${moment(start).format('YYYY-MM-DD')}/to/${moment(end)
    .add(-1, 'day')
    .format('YYYY-MM-DD')}`;
  const onClick = useCallback(() => history.push(link), [history, link]);
  return <ResolvedUnitNameLink nodeId={nodeId} href={link} onClick={onClick} />;
};

const CompensationHistory = () => {
  const classes = useStyles();
  const { t } = useTranslation('compensation');
  const { data, loading, error } = useQuery<GetCompensationOrders>(queries.getCompensationOrders);
  const [showLogs, setShowLogs] = useState<string | null>(null);

  if (loading || error || !data?.compensationOrders) {
    return null;
  }

  const orders = data.compensationOrders;
  return (
    <>
      <Typography variant={'h6'} id="history-header">
        {t('compensation:purchased-items')}
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-labelledby="history-header">
          <TableHead>
            <TableRow>
              <TableCell>{t(`compensation:order.id`)}</TableCell>
              <TableCell>{t(`compensation:order.time`)}</TableCell>
              <TableCell>{t(`compensation:item.name`)}</TableCell>
              <TableCell>{t(`compensation:item.supplier`)}</TableCell>
              <TableCell>{t(`compensation:summary.quantity`)}</TableCell>
              <TableCell>{t(`compensation:summary.unit-price`)}</TableCell>
              <TableCell>{t(`compensation:summary.total-price`)}</TableCell>
              <TableCell>{t(`compensation:purchase.media`)}</TableCell>
              <HasPermission check="canManageUsers">
                <TableCell>{t(`compensation:order.ordered-by`)}</TableCell>
              </HasPermission>
              <TableCell>{t(`compensation:order.status`)}</TableCell>
              <TableCell>{t(`compensation:order.certificate`)}</TableCell>
              <TableCell>{t(`compensation:order.compensation-for-node`)}</TableCell>
              <TableCell>{t(`compensation:purchase.compensation-for-time-range`)}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow key={order.orderId} hover aria-labelledby={`row-${order.orderId}-label`}>
                <TableCell component="th" scope="row" id={`row-${order.orderId}-label`} className={classes.row}>
                  <Link onClick={() => setShowLogs(order.orderId)}>{order.orderId}</Link>
                </TableCell>
                <TableCell>{moment(order.created).format(TIME_FORMAT)}</TableCell>
                <TableCell>{order.compensationItemName}</TableCell>
                <TableCell>{order.supplier?.name}</TableCell>
                <TableCell>
                  <AmountCO2 value={order.quantity} />
                </TableCell>
                <TableCell>
                  <Price price={{ value: order.pricePerUnit, currency: order.currency }} per={UNIT_CO2} />
                </TableCell>
                <TableCell>
                  <Price price={{ value: order.totalPrice, currency: order.currency }} />
                </TableCell>
                <TableCell>{order.medium && t(`compensation:medium.${order.medium}`)}</TableCell>
                <HasPermission check="canManageUsers">
                  <TableCell>
                    {order.orderedBy?.firstName} {order.orderedBy?.lastName}
                  </TableCell>
                </HasPermission>
                <TableCell>
                  <OrderStatus status={order.status} />
                </TableCell>
                <TableCell>
                  {order.certificateLink && (
                    <Link target="_blank" href={order.certificateLink}>
                      {order?.certificateId ?? t(`compensation:order.certificate`)}
                    </Link>
                  )}
                </TableCell>
                <TableCell>
                  {order.distributionDetails && <CompensationLink {...order.distributionDetails} />}
                </TableCell>
                <TableCell>
                  {order.distributionDetails &&
                    moment(order.distributionDetails.start).format(TIME_FORMAT) +
                      ' - ' +
                      moment(order.distributionDetails.end).format(TIME_FORMAT)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showLogs && <CompensationOrderLogs orderId={showLogs} onClose={() => setShowLogs(null)} />}
    </>
  );
};

export default CompensationHistory;
