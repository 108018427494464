import { ApolloError, ApolloProvider, useApolloClient } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import SdkMessage from 'global-components/containers/SdkMessage/SdkMessage';
import ErrorInDataFetcher from 'global-components/presentational/Widget/components/ErrorInDataFetcher/ErrorInDataFetcher';
import { ProcessResult } from 'global-graphql/__generated__/ProcessResult';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { lighten } from '@material-ui/core/styles';

export type ProcessResultHandler = (
  processResult: ProcessResult | undefined | null,
  apolloError?: ApolloError | undefined
) => {
  result?: any;
  processError?: JSX.Element;
  error?: ApolloError;
};

type Props = {
  showErrorInSnackbar?: boolean;
};

const useStyles = makeStyles((theme) => ({
  error: {
    '& .MuiLink-root': {
      color: lighten(theme.palette.primary.main, 0.5),
      textDecorationLine: 'underline',
      textDecorationThickness: '1px',
      '&:hover': {
        textDecorationThickness: '2px',
      },
    },
    '& .MuiTypography-colorError': {
      textDecorationLine: 'line-through',
      '&:hover': {
        textDecorationLine: 'underline',
      },
    },
  },
}));

const useProcessResultHandler = ({ showErrorInSnackbar }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();
  const classes = useStyles();
  return useCallback<ProcessResultHandler>(
    (processResult, apolloError) => {
      if (apolloError) {
        if (showErrorInSnackbar) {
          enqueueSnackbar(<ErrorInDataFetcher emphasise={true} error={apolloError} inline={true} />, {
            variant: 'error',
            preventDuplicate: true,
          });
        }
        return { error: apolloError };
      }
      if (processResult) {
        switch (processResult.__typename) {
          case 'ProcessOkResult':
            return { result: processResult.value };
          case 'ProcessingError':
            const processError = (
              <SdkMessage templateId={processResult.template.id} replacements={processResult.replacements} />
            );
            const key =
              processResult.template.id +
              '|' +
              processResult.replacements.map((value) => JSON.stringify(value)).join('|');
            if (showErrorInSnackbar) {
              enqueueSnackbar(
                <ApolloProvider client={client}>
                  <div className={classes.error}>{processError}</div>
                </ApolloProvider>,
                { variant: 'error', preventDuplicate: true, key }
              );
            }
            return { processError };
        }
      }
      return {};
    },
    [enqueueSnackbar, showErrorInSnackbar, classes, client]
  );
};

export default useProcessResultHandler;
