import { ComponentType, FunctionComponent } from 'react';
import { useTranslation, UseTranslationResponse } from 'react-i18next';

const translate = (namespace: string | string[]) => {
  return <P extends {}>(Component: ComponentType<P & Pick<UseTranslationResponse, 't'>>) => {
    const wrappedComponent: FunctionComponent<Omit<P, 't'>> = function WrappedComponent(props) {
      const [t] = useTranslation(namespace, { useSuspense: false });

      return <Component t={t} {...(props as P)} />;
    };

    wrappedComponent.displayName = 'Translate(' + (Component.displayName || Component.name || 'Component') + ')';

    return wrappedComponent;
  };
};

export default translate;
