import { createModel } from '@rematch/core';
import { RootModel } from 'global-store/models';
import { FormData } from '../presentational/DashboardBody/presentational/WidgetConfigurationDialog/WidgetConfigurationDialog';

export type TileConfigurationStateModel = {
  dialogState: boolean;
  initialValues?: Partial<FormData>;
  tileId?: number | null;
  savePristine?: boolean;
};

export const dashboardTileConfiguration = createModel<RootModel>()({
  state: { dialogState: false } as TileConfigurationStateModel,
  reducers: {
    handleTileConfigurationState: (state, payload: TileConfigurationStateModel) => ({ ...payload }),
  },
});
