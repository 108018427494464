import { Fragment, FunctionComponent } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Close from '@material-ui/icons/Close';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import { useTranslation } from 'react-i18next';

export type Props = {
  webShopLink: string | null;
  closeSnackbar: () => void;
};

export const ActionComponent: FunctionComponent<Props> = ({ webShopLink, closeSnackbar }) => {
  const { t } = useTranslation('application-subscription-expiry', { useSuspense: false });

  return (
    <Fragment>
      {webShopLink && (
        <Tooltip title={t('application-subscription-expiry:show-in-webshop')!}>
          <IconButton color="inherit" component="a" target="_webshop" href={webShopLink}>
            <ShoppingCart />
          </IconButton>
        </Tooltip>
      )}

      <IconButton color="inherit" onClick={closeSnackbar} data-test-type="application-subscription-close-expiry-hint">
        <Close />
      </IconButton>
    </Fragment>
  );
};

export default ActionComponent;
