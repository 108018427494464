import { createModel } from '@rematch/core';
import { RootModel } from 'global-store/models';

export type TileMaximizeStateModel = {
  maximizedTile: number | null;
};

export const dashboardTileMaximizeConfiguration = createModel<RootModel>()({
  state: { maximizedTile: null } as TileMaximizeStateModel,
  reducers: {
    maximizeTile: (state, maximizedTile: number | null) => ({ maximizedTile }),
  },
});
