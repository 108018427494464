import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Theme } from 'global-utils/uiThemeConfig';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

const styles = (theme: Theme) => ({
  container: {
    padding: theme.spacing(),
  },
});

export interface Props extends WithStyles<typeof styles> {}

export const UnknownRenderer: FunctionComponent<Props> = ({ classes }) => {
  const { t } = useTranslation('widgets');

  return <span className={classes.container}>{t('widgets:unknown-renderer')}</span>;
};

export default withStyles(styles)(UnknownRenderer);
