import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import { DeepPartial } from 'redux';
import { reducer as form } from 'redux-form';
import { models, RootModel } from './models';

export const initStore = (initialState?: DeepPartial<StoreState>) =>
  init({
    models,
    redux: {
      initialState,
      reducers: {
        form,
      },
    },
  });

export const store = initStore();

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type StoreState = RematchRootState<RootModel, {}>;
