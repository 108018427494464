import { useQuery } from '@apollo/client';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import HeaderAppBar from 'global-components/presentational/HeaderAppBar/HeaderAppBar';
import { LargerThenMobile, Mobile } from 'global-components/presentational/Responsive/Responsive';
import { UnitsRootNode } from 'global-screens/Units/graphql/__generated__/UnitsRootNode';
import { queries } from 'global-screens/Units/graphql/queries';
import { FC, forwardRef, Fragment, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, NavLinkProps } from 'react-router-dom';
import HasPermission, { AvailableChecks } from '../../presentational/HasPermission/HasPermission';
import Notifications from '../Notifications/Notifications';
import logo from './assets/connect-enlight.svg';
import AccountDrawer from './presentational/AccountDrawer/AccountDrawer';
import AccountItems, { muiItemStyle } from './presentational/AccountDrawer/presentational/AccountItems';
import AccountDrawerItems from './presentational/AccountDrawerItems/AccountDrawerItems';

const AdapterLink = forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => (
  <NavLink innerRef={ref as any} {...props} />
));

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    paddingTop: 64,
  },
  flex: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  menuButton: {
    marginLeft: 20,
    marginRight: -12,
  },
  menuButtonIcon: {
    width: '2rem',
    height: '2rem',
  },
  title: {
    marginRight: 50,
  },
  toolbar: {
    padding: '0 40px',
  },
  mainNavItem: {
    '&.active': {
      color: '#00aae1',
    },
  },
  img: {
    width: '150px',
    transform: 'translateY(3px)',
  },
  menuItemStyle: muiItemStyle,
});

type MenuEntry = {
  menuLink: string;
  menuText: string;
  check?: AvailableChecks | AvailableChecks[];
  requiresRootNode?: boolean;
};

const menuItems: MenuEntry[] = [
  {
    menuLink: '/dashboard',
    menuText: 'header:dashboard',
    check: ['canViewDashboards', 'canViewCompanyDashboards', 'canViewGlobalDashboards'],
  },
  { menuLink: '/reports', menuText: 'header:reports', check: ['canReadReports'] },
  { menuLink: '/measurements', menuText: 'header:measurements', requiresRootNode: true },
  { menuLink: '/units', menuText: 'header:units', requiresRootNode: true },
  { menuLink: '/compensation', menuText: 'header:compensation', requiresRootNode: true },
  {
    menuLink: '/monitoring',
    menuText: 'header:monitoring',
    check: ['canManageMonitoringJobs', 'canViewMonitoringJobs'],
  },
  { menuLink: '/tariffs', menuText: 'header:tariffs', requiresRootNode: true },
];

const CheckedComponent: FC<Pick<MenuEntry, 'check' | 'requiresRootNode'>> = ({
  children,
  check,
  requiresRootNode = false,
}) => {
  const { data } = useQuery<UnitsRootNode>(queries.rootNode, { skip: !requiresRootNode });

  if (requiresRootNode && !data?.root) {
    return null;
  }
  if (check !== undefined) {
    return <HasPermission check={check}>{children}</HasPermission>;
  }
  return <>{children}</>;
};

const Header: FC = () => {
  const [showAccountDrawer, setShowAccountDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const classes = useStyles();
  const { t } = useTranslation('header', { useSuspense: false });

  const toggleDrawer = (drawerState: boolean) => () => setShowAccountDrawer(drawerState);

  const handleClick = (event: SyntheticEvent) => {
    const anchorEl = event.currentTarget as HTMLElement;
    setAnchorEl(anchorEl);
    setShowAccountDrawer(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowAccountDrawer(false);
  };

  return (
    <div className={classes.root}>
      <HeaderAppBar>
        <Toolbar className={classes.toolbar}>
          <div className={classes.flex}>
            <Link to="/" className={classes.title}>
              <img src={logo} className={classes.img} alt="logo" />
            </Link>

            <LargerThenMobile>
              <nav>
                {menuItems.map((item) => (
                  <CheckedComponent key={item.menuLink} check={item.check} requiresRootNode={item.requiresRootNode}>
                    <Button to={item.menuLink} color="inherit" className={classes.mainNavItem} component={AdapterLink}>
                      {t(item.menuText)}
                    </Button>
                  </CheckedComponent>
                ))}
              </nav>
            </LargerThenMobile>
          </div>

          <HasPermission check={'canReadNotifications'}>
            <Notifications />
          </HasPermission>

          <LargerThenMobile>
            <IconButton
              color="inherit"
              className={classes.menuButton}
              onClick={toggleDrawer(true)}
              data-test-type="header-drawer-button"
            >
              <AccountCircle className={classes.menuButtonIcon} />
            </IconButton>
          </LargerThenMobile>

          <Mobile>
            <Fragment>
              <IconButton onClick={handleClick}>
                <MenuIcon />
              </IconButton>

              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <AccountItems>
                  {menuItems.map((item) => (
                    <CheckedComponent key={item.menuLink} check={item.check} requiresRootNode={item.requiresRootNode}>
                      <MenuItem
                        to={item.menuLink}
                        component={AdapterLink}
                        onClick={handleClose}
                        className={classes.menuItemStyle}
                      >
                        {t(item.menuText)}
                      </MenuItem>
                    </CheckedComponent>
                  ))}
                  <Divider />
                  <AccountDrawerItems />
                </AccountItems>
              </Menu>
            </Fragment>
          </Mobile>
        </Toolbar>
      </HeaderAppBar>

      <AccountDrawer accountDrawerState={showAccountDrawer} toggleDrawer={toggleDrawer}>
        <AccountDrawerItems />
      </AccountDrawer>
    </div>
  );
};

export default Header;
