import { Replacements_MeasurementDataLink } from 'global-graphql/__generated__/Replacements';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { AdditionalLinkProps } from '../../types';
import BrokenLink from '../BrokenLink/BrokenLink';
import ResolvedUnitNameLink from '../ResolvedUnitNameLink/ResolvedUnitNameLink';

type Props = Replacements_MeasurementDataLink & AdditionalLinkProps;

const MeasurementDataLink: FC<Props> = ({ measurement, startDate, endDate, fallbackText, ...additionalLinkProps }) => {
  const history = useHistory();
  if (!measurement) {
    return <BrokenLink text={additionalLinkProps.customLabel || fallbackText} />;
  }
  const path =
    startDate && endDate
      ? `/measurements/${measurement.id}/from/${startDate}/to/${endDate}`
      : `/measurements/${measurement.id}`;

  const open = () => history.push(path);

  return (
    <ResolvedUnitNameLink
      {...additionalLinkProps}
      fallbackText={fallbackText}
      onClick={open}
      nodeId={measurement.id}
      href={path}
    />
  );
};

export default MeasurementDataLink;
