import { useMutation } from '@apollo/client';
import { DeleteNotification, DeleteNotificationVariables } from 'global-graphql/__generated__/DeleteNotification';
import { Notifications } from 'global-graphql/__generated__/Notifications';
import { mutations, queries } from 'global-graphql/queries';
import { useCallback } from 'react';

const refetchQueries = [{ query: queries.notifications }];

const useDeleteNotification = (notificationId: string) => {
  const [mutation] = useMutation<DeleteNotification, DeleteNotificationVariables>(mutations.deleteNotification);

  return useCallback(
    () =>
      mutation({
        variables: { notificationId },
        update: (proxy) => {
          const allNotificationsResult = proxy.readQuery<Notifications>({ query: queries.notifications });

          if (allNotificationsResult) {
            const updatedNotifications = allNotificationsResult.notifications.filter(
              (notification) => notification.id !== notificationId
            );

            proxy.writeQuery<Notifications>({
              query: queries.notifications,
              data: { notifications: updatedNotifications },
            });
          }
        },
        refetchQueries: refetchQueries,
      }),
    [mutation, notificationId]
  );
};

export default useDeleteNotification;
