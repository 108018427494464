import Widget from 'global-components/presentational/Widget/Widget';
import { FC } from 'react';

type Props = {
  node: string;
  startDate: string;
  endDate: string;
  kpi: string;
  size?: number | string;
  showErrorInSnackbar?: boolean;
};

const KpiDonut: FC<Props> = ({ node, startDate, endDate, kpi, size = 220, showErrorInSnackbar }) => (
  <Widget
    widgetId="common:widget:kpi"
    height={size}
    width={size}
    config={{ dateRange: { startDate, endDate }, node, kpi }}
    showErrorInSnackbar={showErrorInSnackbar}
  />
);

export default KpiDonut;
