import { createModel } from '@rematch/core';
import { RootModel } from 'global-store/models';

export type TariffDetailsDialogState = {
  open: boolean;
  id: string | null;
};

export const tariffDetailsDialog = createModel<RootModel>()({
  state: { open: false, id: null } as TariffDetailsDialogState,
  reducers: {
    handleTariffDetailsDialogState: (state, payload: TariffDetailsDialogState) => ({
      ...state,
      ...payload,
    }),
  },
});
