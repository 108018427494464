import { Replacements_NodeOverviewLink } from 'global-graphql/__generated__/Replacements';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { AdditionalLinkProps } from '../../types';
import BrokenLink from '../BrokenLink/BrokenLink';
import ResolvedUnitNameLink from '../ResolvedUnitNameLink/ResolvedUnitNameLink';

type Props = Replacements_NodeOverviewLink & AdditionalLinkProps;

const NodeOverviewLink: FC<Props> = ({ fallbackText, overviewNode, ...additionalLinkProps }) => {
  const history = useHistory();

  if (!overviewNode) {
    return <BrokenLink text={additionalLinkProps.customLabel || fallbackText} />;
  }

  const nodeId = overviewNode.id;

  const path = `/units/${nodeId}`;
  const open = () => history.push(path);
  return (
    <ResolvedUnitNameLink
      {...additionalLinkProps}
      fallbackText={fallbackText}
      onClick={open}
      nodeId={overviewNode.id}
      href={path}
    />
  );
};

export default NodeOverviewLink;
