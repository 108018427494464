import { createModel } from '@rematch/core';
import { RootModel } from 'global-store/models';

export type SubscriptionStatisticsModel = {
  showSubscriptionStatistics: boolean;
};

export const subscriptionStatistics = createModel<RootModel>()({
  state: {
    showSubscriptionStatistics: false,
  } as SubscriptionStatisticsModel,
  reducers: {
    showSubscriptionStatistics: (state) => ({ ...state, showSubscriptionStatistics: true }),
    hideSubscriptionStatistics: (state) => ({ ...state, showSubscriptionStatistics: false }),
  },
});
