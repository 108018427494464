import { createModel } from '@rematch/core';
import { RootModel } from 'global-store/models';

export interface MeasurandWhitelistModel {
  measurandWhitelistScreenState: boolean;
  removedUsedMeasurands: number;
}

export const measurandWhitelistDialog = createModel<RootModel>()({
  state: {
    measurandWhitelistScreenState: false,
    removedUsedMeasurands: 0,
  } as MeasurandWhitelistModel,
  reducers: {
    handleMeasurandWhitelistDialogState: (state, payload: boolean) => ({
      ...state,
      measurandWhitelistScreenState: payload,
    }),

    changeRemovedUsedMeasurand: (state, removedUsedMeasurands: number) => ({ ...state, removedUsedMeasurands }),
  },
});
