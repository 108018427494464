import { FC } from 'react';
import Widget from 'global-components/presentational/Widget/Widget';

type Props = {
  setSelectedNode: (nodeId: string) => void;
  selectedNode?: string;
};

const SingleNodeSelection: FC<Props> = ({ setSelectedNode, selectedNode }) => (
  <Widget
    widgetId="common:widget:single-node-selection"
    height={'100%'}
    config={{ node: selectedNode }}
    onConfigurationChange={setSelectedNode}
    emphasiseErrors={true}
  />
);

export default SingleNodeSelection;
