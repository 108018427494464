import { createModel } from '@rematch/core';
import { RootModel } from 'global-store/models';

export type CredentialManagementState = {
  managementScreenState: boolean;
};

export const credentialManagementDialog = createModel<RootModel>()({
  state: {
    managementScreenState: false,
  } as CredentialManagementState,
  reducers: {
    showManagementScreen: (state, managementScreenState: boolean) => ({ ...state, managementScreenState }),
  },
});
