import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Theme } from 'global-utils/uiThemeConfig';
import { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(),
    width: '100%',
  },
  text: {
    paddingBottom: '1em',
  },
  config: {
    border: `1px solid ${theme.palette.divider}`,
    margin: 0,
    overflowX: 'auto',
    padding: theme.spacing(),
    width: '100%',
  },
}));

export interface Props {
  error: Error;
  widgetId: string;
  config: Record<string, any>;
}

export const ErrorInRenderer: FunctionComponent<Props> = ({ error, widgetId, config }) => {
  const { t } = useTranslation('widgets');
  const classes = useStyles();

  useEffect(() => {
    console.error('Error in renderer. Error message was: ', error);
  }, [error]);

  return (
    <div className={classes.container}>
      <Typography classes={{ root: classes.text }}>{t('widgets:error-in-renderer')}</Typography>
      <Typography classes={{ root: classes.text }}>{t('widgets:error-in-renderer-config')}</Typography>
      <pre className={classes.config}>{JSON.stringify({ widgetId, config }, null, 2)}</pre>
    </div>
  );
};

export default ErrorInRenderer;
