import { Dispatch, StoreState } from 'global-store/store';
import popupUrlConfigWithId from 'global-utils/popupUrlConfigWithId/popupUrlConfigWithId';
import savePopupScreenStateInURL from 'global-utils/savePopupScreenStateInURL/savePopupScreenStateInURL';

const openScreen = (dispatch: Dispatch, id: string) =>
  dispatch.tariffDetailsDialog.handleTariffDetailsDialogState({ open: true, id: id === '' ? null : id });

const getState = ({ tariffDetailsDialog }: StoreState) => ({
  id: tariffDetailsDialog.id,
  open: tariffDetailsDialog.open,
});

export default savePopupScreenStateInURL(popupUrlConfigWithId('tariffDetails', openScreen, getState));
