import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HasPermission from 'global-components/presentational/HasPermission/HasPermission';
import NodeTreeAndDateSelection from 'global-components/presentational/NodeTreeAndDateSelection/NodeTreeAndDateSelection';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CompensationHistory from './presentational/CompensationHistory/CompensationHistory';
import CompensationItems from './presentational/CompensationItems/CompensationItems';
import DonutCharts from './presentational/DonutCharts/DonutCharts';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 0,
    width: '100%',
  },
}));
const Compensation: FC = () => {
  const { t } = useTranslation('compensation');
  const classes = useStyles();

  return (
    <NodeTreeAndDateSelection
      pathName="compensation"
      renderBody={(selectedNode, startDate, endDate) => (
        <Grid spacing={4} container className={classes.container}>
          {selectedNode && startDate && endDate && (
            <>
              <Grid item xs={12} container>
                <DonutCharts node={selectedNode} startDate={startDate} endDate={endDate} showErrorInSnackbar />
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'h6'}>{t('compensation:available-items')}</Typography>
                <CompensationItems selectedNode={selectedNode} startDate={startDate} endDate={endDate} />
              </Grid>
            </>
          )}
          <HasPermission check={'canViewCompensationHistory'}>
            <Grid item xs={12}>
              <CompensationHistory />
            </Grid>
          </HasPermission>
        </Grid>
      )}
    />
  );
};

export default Compensation;
