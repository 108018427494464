import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'global-utils/uiThemeConfig';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function NoMatch() {
  const { t } = useTranslation('no-match');
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>{t('no-match:404')}</Paper>
    </Grid>
  );
}

export default NoMatch;
