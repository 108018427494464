import { Dispatch, StoreState } from 'global-store/store';
import popupUrlConfigWithId from 'global-utils/popupUrlConfigWithId/popupUrlConfigWithId';
import savePopupScreenStateInURL from 'global-utils/savePopupScreenStateInURL/savePopupScreenStateInURL';

const openScreen = (dispatch: Dispatch, id: string) =>
  dispatch.unitDialog.handleUnitDialogState({ dialogState: true, id });

const getState = ({ unitDialog }: StoreState) => ({ id: unitDialog.id, open: unitDialog.dialogState });

export default savePopupScreenStateInURL(popupUrlConfigWithId('unitDetails', openScreen, getState));
