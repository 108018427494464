import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';

i18n
  .use(XHR)
  // pass the i18n instance to react-i18next.
  .init(
    {
      backend: {
        // window['UI2_APPLICATION_URL'] comes from the version 1 and only the relative path /assets/i18n/{{lng}}/{{ns}}.json in production
        loadPath: `${window['UI2_APPLICATION_URL'] || ''}/assets/i18n/{{lng}}/{{ns}}.json`,
      },
      lng: 'de',
      fallbackLng: 'de',
      defaultNS: 'default',
      debug: false,
      react: {
        wait: false,
      },
    },
    undefined
  );

export default i18n;
