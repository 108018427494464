import { CompensationMedium } from '@ence/apis-graphql';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  chip: {
    marginRight: theme.spacing(1),
  },
}));

type Props = {
  media: CompensationMedium[];
};

const ValidMedia: FC<Props> = ({ media }) => {
  const classes = useStyles();
  const { t } = useTranslation('compensation');
  return (
    <>
      {media?.map((value) => (
        <Chip
          key={value}
          className={classes.chip}
          label={t(`compensation:medium.${value}`)}
          variant="outlined"
          color="primary"
          size="small"
        />
      ))}
    </>
  );
};

export default ValidMedia;
