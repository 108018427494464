import { ApolloError } from '@apollo/client';

const extractBadRequestMessage = (error: any): string | null => {
  if (error instanceof ApolloError) {
    const badRequestError = error.graphQLErrors.find(
      (error) => error.extensions && error.extensions.code === 'BAD_REQUEST'
    );

    if (badRequestError) return badRequestError.message;
  }

  return null;
};

export default extractBadRequestMessage;
