import gql from 'graphql-tag';

export const fragments = {
  unitTree: gql`
    fragment UnitTree on UnitsGroupedByType {
      _id
      type
      items {
        ...UnitItemInTree
      }
    }
  `,
  unitItemInTree: gql`
    fragment UnitItemInTree on Unit {
      id
      name
      hasChildren
      type {
        _id
        mainType
        subType
      }
      parent {
        id
      }
      archiveTime
      measurementSource {
        id
      }
    }
  `,
  unitName: gql`
    fragment UnitName on Unit {
      id
      name
    }
  `,
  pathToUnit: gql`
    fragment PathToUnit on Unit {
      id
      parent {
        id
      }
      ancestors {
        id
        name
        parent {
          id
        }
      }
    }
  `,
  replacements: gql`
    fragment Replacements on Replacements {
      __typename
      ... on BaseReplacement {
        placeholder
      }
      ... on BaseLinkReplacement {
        fallbackText
      }
      ... on BalanceVisualizationLink {
        balanceVisualizationNode: node {
          id
        }
      }
      ... on DateReplacement {
        date
      }
      ... on DateTimeReplacement {
        dateTime
      }
      ... on MeasurementDataLink {
        startDate
        endDate
        measurement: node {
          id
        }
      }
      ... on NodeDetailsLink {
        detailsNode: node {
          id
        }
      }
      ... on NodeOverviewLink {
        overviewNode: node {
          id
        }
      }
      ... on NumberReplacement {
        number
        maximumFractionDigits
      }
      ... on StringReplacement {
        value
      }
      ... on SubscriptionLink {
        subscription {
          group
          member
        }
      }
      ... on ReportsLink {
        reportNode: node {
          id
        }
        reportTemplate {
          _id
        }
        startDate
      }
      ... on MonitoringJobLink {
        job {
          id
        }
      }
      ... on CompensationDetailsLink {
        startDate
        endDate
        compensationNode: node {
          id
        }
      }
    }
  `,
  badRequest: gql`
    fragment BadRequestErrors on BadRequestErrors {
      __typename
      badRequest {
        field
        message
      }
    }
  `,
  conflict: gql`
    fragment ConflictError on ConflictError {
      __typename
      message
    }
  `,
  widgetDefinition: gql`
    fragment WidgetDefinition on Widget {
      id
      fullName
      description
      renderer
      providesDefaultValue
      defaultValueDescription
      alternativeSelectionWidgets {
        id
      }
      properties {
        key
        name
        description
        required
        dynamicType
        dynamicKeyLabel
        dynamicValueLabel
        dynamicValueAdderLabel
        selectionWidget {
          id
          fullName
          alternativeSelectionWidgets {
            id
          }
        }
      }
    }
  `,
  processResult: gql`
    fragment ProcessResult on ProcessResult {
      __typename
      ... on ProcessOkResult {
        value
      }
      ... on ProcessingError {
        template {
          id
        }
        replacements {
          ...Replacements
        }
      }
    }
  `,
  userSettings: gql`
    fragment UserSettings on UserSettings {
      id
      csvExportDelimiter
      csvNumberFormatLanguage
    }
  `,
};

export const queries = {
  unitName: gql`
    query UnitNameQuery($id: ID!) {
      unit(id: $id) {
        ...UnitName
        archiveTime
      }
    }

    ${fragments.unitName}
  `,
  rootId: gql`
    query RootNode {
      root {
        ...UnitItemInTree
      }
    }

    ${fragments.unitItemInTree}
  `,
  unitActionsCheck: gql`
    query CheckPossibleActions($sourceUnits: [ID!]!, $targetUnits: [ID!]!) {
      unitActionsCheck(sourceUnits: $sourceUnits, targetUnits: $targetUnits) {
        _id
        targetUnit
        possibleActions
      }
    }
  `,
  possibleActionsBatch: gql`
    query GetPossibleActionsBatch($unitIds: [ID!]!) {
      possibleActionsBatch(unitsIds: $unitIds) {
        __typename
        id
        possibleActions
      }
    }
  `,
  unitChildTree: gql`
    query UnitChildTree($unitId: ID!) {
      unit(id: $unitId) {
        ...UnitItemInTree
        children {
          ...UnitTree
        }
      }
    }

    ${fragments.unitTree}
    ${fragments.unitItemInTree}
  `,
  unitTypes: gql`
    query UnitTypes {
      unitTypes {
        _id
        mainType
        name
      }
    }
  `,
  notificationTemplates: gql`
    query NotificationTemplates {
      notificationTemplates {
        id
        group
        level
        summary
        template
      }
    }
  `,
  notifications: gql`
    query Notifications {
      notifications {
        id
        template {
          id
        }
        createdAt
        replacements {
          ...Replacements
        }
      }
    }
    ${fragments.replacements}
  `,
  subscriptions: gql`
    query Subscriptions($companyId: ID!) {
      subscriptions(companyId: $companyId) {
        id
        autoExtends
        dateRange {
          end
        }
        type {
          _id
          group
          member
          webshopLink
        }
      }
    }
  `,
  subscriptionTypes: gql`
    query SubscriptionTypes {
      subscriptionTypes {
        _id
        group
        member
        webshopLink
        shortName
        fullName
      }
    }
  `,

  reportTemplates: gql`
    query ReportTemplates {
      reportTemplates {
        _id
        shortName
        fullName
      }
    }
  `,
  monitoringJobNames: gql`
    query MonitoringJobNames {
      monitoringJobs {
        id
        name
      }
    }
  `,
  allWidgets: gql`
    query AllWidgets {
      widgets {
        ...WidgetDefinition
      }
    }

    ${fragments.widgetDefinition}
  `,
  widgetDefaultValues: gql`
    query WidgetDefaultValues($widgetIds: [ID!]!) {
      widgetDefaultValues(widgetIds: $widgetIds)
    }
  `,
  widgetComputeResult: gql`
    query WidgetComputeResult($widgetId: ID!, $configuration: [WidgetConfigurationInput!]!) {
      widgetComputeResult(widgetId: $widgetId, configuration: $configuration) {
        ...ProcessResult
      }
    }
    ${fragments.replacements}
    ${fragments.processResult}
  `,
  widgetAlternativeRenderResult: gql`
    query WidgetAlternativeRenderResult(
      $widgetId: ID!
      $targetShapeWidgetId: ID!
      $configuration: [WidgetConfigurationInput!]!
    ) {
      widgetAlternativeRenderResult(
        widgetId: $widgetId
        targetShapeWidgetId: $targetShapeWidgetId
        configuration: $configuration
      ) {
        ...ProcessResult
      }
    }
    ${fragments.replacements}
    ${fragments.processResult}
  `,
  widgetLazyLoad: gql`
    query WidgetLazyLoad($widgetId: ID!, $request: Object) {
      widgetLazyLoad(widgetId: $widgetId, request: $request) {
        ...ProcessResult
      }
    }
    ${fragments.replacements}
    ${fragments.processResult}
  `,
  renderers: gql`
    query Renderers {
      renderer {
        id
        name
        description
        entryPoint
        resultSchema
      }
    }
  `,
  rootNodeWithChildren: gql`
    query RootNodeWithChildren {
      root {
        id
        children {
          items {
            id
            name
          }
        }
      }
    }
  `,
  getSkdMessageTemplates: gql`
    query GetSdkMessageTemplates {
      sdkMessageTemplates {
        id
        summary
        template
      }
    }
  `,
  userSettings: gql`
    query GetUserSettings {
      userSettings {
        ...UserSettings
      }
    }
    ${fragments.userSettings}
  `,
  tariffs: gql`
    query GetTariffs {
      tariffs {
        id
        name
        start
        end
        sourceFormat
        currency
      }
    }
  `,
};

export const mutations = {
  deleteUnits: gql`
    mutation DeleteUnits($unitIds: [ID!]!) {
      deleteUnits(unitIds: $unitIds)
    }
  `,
  moveUnits: gql`
    mutation MoveUnits($unitIds: [ID!]!, $targetParent: ID!) {
      moveUnits(unitIds: $unitIds, targetParent: $targetParent) {
        ...PathToUnit

        id
      }
    }

    ${fragments.pathToUnit}
  `,
  copyUnits: gql`
    mutation CopyUnits($unitIds: [ID!]!, $targetParent: ID!) {
      copyUnits(unitIds: $unitIds, targetParent: $targetParent) {
        id
      }
    }
  `,
  deleteNotification: gql`
    mutation DeleteNotification($notificationId: ID!) {
      deleteNotification(notificationId: $notificationId)
    }
  `,
  updateUserSettings: gql`
    mutation UpdateUserSettings($settings: UserSettingsInput!) {
      updateUserSettings(settings: $settings) {
        __typename
        ... on UserSettings {
          ...UserSettings
        }
        ... on BadRequestErrors {
          ...BadRequestErrors
        }
      }
    }
    ${fragments.badRequest}
    ${fragments.userSettings}
  `,
};
