import { createModel } from '@rematch/core';
import { RootModel } from '../models';

export enum NOTIFICATION_TYPE {
  NONE,
  UNIT_OPERATION,
  NON_IMMEDIATE_UNIT_CREATION,
}

export interface Notification {
  type: NOTIFICATION_TYPE;
  text: string;
}

export interface UnitOperationNotification extends Notification {
  type: NOTIFICATION_TYPE.UNIT_OPERATION;
  linkedUnitsParent?: string;
  linkText: string;
}

export interface NonImmediateUnitCreationNotification extends Notification {
  type: NOTIFICATION_TYPE.NON_IMMEDIATE_UNIT_CREATION;
}

const initialState: Notification = {
  type: NOTIFICATION_TYPE.NONE,
  text: '',
};

export const notification = createModel<RootModel>()({
  state: { ...initialState } as Notification,
  reducers: {
    setNotification: (state, notification: Notification) => ({ ...state, ...notification }),
    hideNotification: () => ({ ...initialState }),
  },
});
