import { createModel } from '@rematch/core';
import { RootModel } from 'global-store/models';

export interface SubscriptionManagementModel {
  showSubscriptionManagement: boolean;
  companyId: string;
}

export const subscriptionManagement = createModel<RootModel>()({
  state: {
    showSubscriptionManagement: false,
    companyId: '',
  } as SubscriptionManagementModel,
  reducers: {
    showSubscriptionManagement: (state, companyId: string) => ({
      ...state,
      companyId,
      showSubscriptionManagement: true,
    }),
    hideSubscriptionManagement: (state) => ({ ...state, showSubscriptionManagement: false }),
  },
});
