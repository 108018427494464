import { Replacements_NodeDetailsLink } from 'global-graphql/__generated__/Replacements';
import { Dispatch } from 'global-store/store';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { AdditionalLinkProps } from '../../types';
import BrokenLink from '../BrokenLink/BrokenLink';
import ResolvedUnitNameLink from '../ResolvedUnitNameLink/ResolvedUnitNameLink';

type Props = Replacements_NodeDetailsLink & AdditionalLinkProps;

const NodeDetailsLink: FC<Props> = ({ fallbackText, detailsNode, ...additionalLinkProps }) => {
  const dispatch = useDispatch<Dispatch>();

  if (!detailsNode) {
    return <BrokenLink text={additionalLinkProps.customLabel || fallbackText} />;
  }

  const open = () => dispatch.unitDialog.handleUnitDialogState({ dialogState: true, id: detailsNode.id });
  return (
    <ResolvedUnitNameLink
      {...additionalLinkProps}
      fallbackText={fallbackText}
      onClick={open}
      nodeId={detailsNode.id}
      href={`/units/${detailsNode.id}?unitDetails=${detailsNode.id}`}
    />
  );
};

export default NodeDetailsLink;
