import Snackbar from '@material-ui/core/Snackbar';
import moment from 'moment';
import { Fragment, FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSubscriptionsQuery from './hooks/useSubscriptionsQuery/useSubscriptionsQuery';
import ActionComponent from './presentational/ActionComponent/ActionComponent';

export interface Props {}

export const ApplicationSubscriptionExpiryHint: FunctionComponent<Props> = () => {
  const subscriptions = useSubscriptionsQuery();
  const [isSnackbarOpen, setSnackbarState] = useState(true);
  const { t } = useTranslation('application-subscription-expiry', { useSuspense: false });

  const applicationSubscriptions = subscriptions.filter(
    (subscription) => subscription.type.group === 'APPLICATION' && subscription.type.member === 'ACCESS'
  );

  const latestApplicationSubscription = applicationSubscriptions.length
    ? applicationSubscriptions.reduce((latestSubscription, subscription) =>
        latestSubscription.dateRange.end > subscription.dateRange.end ? latestSubscription : subscription
      )
    : null;

  if (!latestApplicationSubscription || latestApplicationSubscription.autoExtends) {
    return null;
  }

  const expiryDate = moment(latestApplicationSubscription.dateRange.end).endOf('day');
  const dataDeletionDate = expiryDate.clone().add(183, 'days');
  const numberOfMonthsTillExpiry = expiryDate.diff(moment(Date.now()), 'months', true);

  if (numberOfMonthsTillExpiry > 2) return null;

  const closeSnackbar = () => {
    setSnackbarState(false);
  };

  return (
    <Snackbar
      open={isSnackbarOpen}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      message={
        <Fragment>
          {t(
            numberOfMonthsTillExpiry > 0
              ? 'application-subscription-expiry:subscription-about-to-expire'
              : 'application-subscription-expiry:subscription-expired',
            {
              replace: { expiryDate: expiryDate.format('L') },
            }
          )}
          <br />
          {t('subscription-expired-data-deletion-hint', {
            replace: { dataDeletionDate: dataDeletionDate.format('L') },
          })}
        </Fragment>
      }
      action={
        <ActionComponent webShopLink={latestApplicationSubscription.type.webshopLink} closeSnackbar={closeSnackbar} />
      }
    />
  );
};

export default ApplicationSubscriptionExpiryHint;
