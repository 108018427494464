import { ApolloError } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const RenderForbiddenInfoFallback: FC<{}> = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('global', { useSuspense: false });

  useEffect(() => {
    const listener = (ev: PromiseRejectionEvent) => {
      if (ev.reason instanceof ApolloError && ev.reason.networkError?.['status'] === 403) {
        enqueueSnackbar(t('global:forbidden'), { variant: 'error' });
      }
    };
    window.addEventListener('unhandledrejection', listener);
    return () => window.removeEventListener('unhandledrejection', listener, {});
  }, [enqueueSnackbar, t]);
  return <>{props.children}</>;
};

export default RenderForbiddenInfoFallback;
