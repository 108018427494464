import { Notifications_notifications } from 'global-graphql/__generated__/Notifications';
import { NotificationTemplates_notificationTemplates } from 'global-graphql/__generated__/NotificationTemplates';
import { MessageLevel, NotificationGroup } from '../../../../../../__generated__/globalTypes';

export type NotificationsOfTemplate = {
  template: NotificationTemplates_notificationTemplates;
  notifications: Notifications_notifications[];
};

const LEVEL_ORDER = [MessageLevel.ERROR, MessageLevel.WARNING, MessageLevel.INFO];

const sortByLevel = (
  { template: { level: level1 } }: NotificationsOfTemplate,
  { template: { level: level2 } }: NotificationsOfTemplate
) => LEVEL_ORDER.indexOf(level1 ?? MessageLevel.INFO) - LEVEL_ORDER.indexOf(level2 ?? MessageLevel.INFO);

const groupNotifications = (
  templates?: NotificationTemplates_notificationTemplates[] | null,
  notifications?: Notifications_notifications[] | null
) => {
  const result: Partial<Record<NotificationGroup, NotificationsOfTemplate[]>> = {};
  templates?.forEach((template) => {
    const group = result[template.group] || [];
    result[template.group] = group;
    group.push({
      template,
      notifications: notifications?.filter((n) => n.template.id === template.id) ?? [],
    });
    group.sort(sortByLevel);
  });
  return result;
};

export default groupNotifications;
