import { createModel } from '@rematch/core';
import { RootModel } from 'global-store/models';

export type UnassignedMeters = { showMeterAssignmentScreen: boolean };

const initialState: UnassignedMeters = { showMeterAssignmentScreen: false };

export const unassignedMeters = createModel<RootModel>()({
  state: { ...initialState } as UnassignedMeters,
  reducers: {
    showMeterAssignmentScreen: (state) => ({ ...state, showMeterAssignmentScreen: true }),
    hideMeterAssignmentScreen: (state) => ({ ...state, showMeterAssignmentScreen: false }),
  },
});
