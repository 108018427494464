import { createModel } from '@rematch/core';
import { RootModel } from 'global-store/models';

export type DashboardSelectionDialogStateModel = {
  open: boolean;
};

export const dashboardSelectionDialog = createModel<RootModel>()({
  state: { open: false } as DashboardSelectionDialogStateModel,
  reducers: {
    open: (state, open: boolean) => ({ open }),
  },
});
