import { createModel } from '@rematch/core';
import { RootModel } from '../models';

export type Environment = {
  version: string;
  keycloak: {
    url: string;
    realm: string;
    clientId: string;
  };
  webshop: {
    url: string;
    deeplinkSuffix: string;
  };
  supportPhoneNumber: string;
};

export const environment = createModel<RootModel>()({
  state: {
    version: '',
    keycloak: {
      url: '',
      realm: '',
      clientId: '',
    },
    webshop: {
      url: '',
      deeplinkSuffix: '',
    },
    supportPhoneNumber: '',
  } as Environment,
  reducers: {
    setEnvironment: (state, environment: Environment) => ({ ...state, ...environment }),
  },
});
