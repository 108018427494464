import { useQuery } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import { FC, useState } from 'react';
import {
  GetCompensationItems,
  GetCompensationItems_compensationItems,
} from '../../graphql/__generated__/GetCompensationItems';
import { queries } from '../../graphql/queries';
import CompensationItemDetails from './presentational/CompensationItemDetails/CompensationItemDetails';
import CompensationItemTable from './presentational/CompensationItemTable/CompensationItemTable';

type Props = {
  selectedNode: string;
  startDate: string;
  endDate: string;
};
const CompensationItems: FC<Props> = ({ selectedNode, startDate, endDate }) => {
  const { data } = useQuery<GetCompensationItems>(queries.getCompensationItems);
  const [selectedItem, setSelectedItem] = useState<GetCompensationItems_compensationItems | null>(null);
  if (!data?.compensationItems) {
    return null;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={7}>
        <CompensationItemTable
          items={data.compensationItems}
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem?.id}
        />
      </Grid>

      <Grid item xs={12} lg={5}>
        {selectedItem && (
          <CompensationItemDetails item={selectedItem} nodeId={selectedNode} startDate={startDate} endDate={endDate} />
        )}
      </Grid>
    </Grid>
  );
};

export default CompensationItems;
