import { createStyles } from '@material-ui/core/styles';
import { globalStyles } from 'global-utils/globalStyles/globalStyles';

const { iconButton } = globalStyles;

export const style = createStyles({
  iconButton,
  sizeIcon: {
    fontSize: 16,
  },
  buttonItem: {
    width: 34,
    height: 34,
    padding: 0,
  },
  checkboxItem: {
    width: 34,
    height: 34,
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  warningIcon: {},
});
