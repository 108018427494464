import { useQuery } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { queries } from 'global-graphql/queries';
import moment from 'moment';
import { FC, ReactNode, useCallback, useEffect } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import DateRangeSelection from '../../widget-delegates/DateRangeSelection';
import SingleNodeSelection from '../../widget-delegates/SingleNodeSelection';
import { RootNodeWithChildren } from 'global-graphql/__generated__/RootNodeWithChildren';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    padding: 0,
  },
  leftSide: {
    overflow: 'hidden',
    overflowY: 'auto',
    height: '100%',
    paddingRight: theme.spacing(5),

    [theme.breakpoints.down('sm')]: {
      height: 'auto !important',
    },
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  rightSideGridItem: {
    // paddingRight: 0,
    // paddingBottom: 0,
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  body: {
    flex: '1 1 auto',
    height: 0,
    overflowY: 'auto',
  },
  dateRange: {
    flexGrow: 1,
  },
  tabs: {
    margin: theme.spacing(1),
  },
}));

type Props = {
  pathName: string;
  renderBody: (selectedNode: string | null, startDate: string | null, endDate: string | null) => ReactNode;
  tabs?: ReactNode;
};

const NodeTreeAndDateSelection: FC<Props> = ({ pathName, renderBody, tabs }) => {
  const classes = useStyles();

  const {
    params: { selectedNode, startDate, endDate },
  } = useRouteMatch<{ selectedNode?: string; startDate?: string; endDate?: string }>();
  const { search: locationSearch } = useLocation();
  const history = useHistory();

  const { data: rooIdData } = useQuery<RootNodeWithChildren>(queries.rootNodeWithChildren, {
    skip: Boolean(selectedNode),
  });

  const definedStartDate =
    startDate ||
    moment()
      .date(1)
      .format('YYYY-MM-DD');
  const definedEndDate = endDate || moment().format('YYYY-MM-DD');

  const setDateRange = useCallback(
    (dateRange: { startDate: string; endDate: string }) => {
      const searchString = locationSearch ? '?' + locationSearch : '';
      history.push(`/${pathName}/${selectedNode}/from/${dateRange.startDate}/to/${dateRange.endDate}${searchString}`);
    },
    [history, selectedNode, locationSearch, pathName]
  );

  const setSelectedNode = useCallback(
    (nodeId: string) => {
      const searchString = locationSearch ? '?' + locationSearch : '';
      const path = `/${pathName}/${nodeId}/from/${definedStartDate}/to/${definedEndDate}${searchString}`;
      if (!startDate || !endDate) {
        history.replace(path);
      } else {
        history.push(path);
      }
    },
    [history, startDate, endDate, definedStartDate, definedEndDate, locationSearch, pathName]
  );

  useEffect(() => {
    if (!selectedNode && rooIdData?.root?.id) {
      const idToSelect =
        [...(rooIdData.root.children?.[0]?.items ?? [])]?.sort((a, b) => a.name.localeCompare(b.name))?.[0]?.id ??
        rooIdData?.root?.id;
      setSelectedNode(idToSelect);
    }
  }, [selectedNode, rooIdData, setSelectedNode]);

  return (
    <Grid container spacing={0} className={classes.container}>
      <Grid item xs={12} md={3} className={classes.leftSide}>
        <SingleNodeSelection selectedNode={selectedNode} setSelectedNode={setSelectedNode} />
      </Grid>

      <Grid item xs={12} md={9} className={classes.rightSideGridItem}>
        <section className={classes.rightSide}>
          <header className={classes.headerContainer}>
            <div className={classes.dateRange}>
              <DateRangeSelection startDate={definedStartDate} endDate={definedEndDate} setDateRange={setDateRange} />
            </div>
            <div className={classes.tabs}>{tabs}</div>
          </header>
          <div className={classes.body}>{renderBody(selectedNode || null, definedStartDate, definedEndDate)}</div>
        </section>
      </Grid>
    </Grid>
  );
};

export default NodeTreeAndDateSelection;
