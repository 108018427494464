import { createModel } from '@rematch/core';
import { RootModel } from 'global-store/models';

export interface ManualMeasurementDataInputDialogState {
  dialogState: boolean;
  id: string | null;
}

export const manualMeasurementDataInputDialog = createModel<RootModel>()({
  state: { dialogState: false, id: '' } as ManualMeasurementDataInputDialogState,
  reducers: {
    handleManualMeasurementDataInputDialogState: (state, payload: ManualMeasurementDataInputDialogState) => ({
      ...state,
      ...payload,
    }),
  },
});
