import { useQuery } from '@apollo/client';
import Link from '@material-ui/core/Link';
import { Replacements_SubscriptionLink } from 'global-graphql/__generated__/Replacements';
import { SubscriptionTypes } from 'global-graphql/__generated__/SubscriptionTypes';
import { queries } from 'global-graphql/queries';
import { FC, MouseEventHandler } from 'react';
import { AdditionalLinkProps } from '../../types';
import BrokenLink from '../BrokenLink/BrokenLink';

type Props = Replacements_SubscriptionLink & AdditionalLinkProps;

const SubscriptionLink: FC<Props> = ({ customLabel, fallbackText, subscription, beforeOpen }) => {
  const { data, error } = useQuery<SubscriptionTypes>(queries.subscriptionTypes, { fetchPolicy: 'cache-first' });

  const group = subscription?.group;
  const member = subscription?.member;
  if (!subscription || error) {
    return <BrokenLink text={customLabel || fallbackText || (subscription ? `${group} ${member}` : null)} />;
  }

  const subscriptionType = data?.subscriptionTypes?.find((type) => type.group === group && type.member === member);

  let label: JSX.Element | string;
  if (customLabel) {
    label = customLabel;
  } else if (subscriptionType) {
    label = subscriptionType.fullName || subscriptionType.shortName;
  } else {
    label = fallbackText || `${group} ${member}`;
  }
  if (subscriptionType?.webshopLink) {
    let onClick: MouseEventHandler<HTMLElement> | undefined = beforeOpen
      ? (e) => {
          beforeOpen(e.currentTarget);
        }
      : undefined;

    return (
      <Link target="webshop" href={subscriptionType.webshopLink} role="link" onClick={onClick}>
        {label}
      </Link>
    );
  } else {
    return <span role="link">{label}</span>;
  }
};

export default SubscriptionLink;
