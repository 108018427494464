import NullComponent from 'global-components/presentational/NullComponent/NullComponent';
import { ComponentType, FC } from 'react';
import { Route, RouteProps } from 'react-router-dom';

type Props = Partial<Pick<RouteProps, 'exact' | 'path' | 'component'>> & {
  accessToken: string;
  component: ComponentType<any>;
};

const ProtectedRoute: FC<Props> = ({ accessToken, component: AppRoute, ...rest }) => {
  return <Route {...rest} component={accessToken ? AppRoute : NullComponent} />;
};

export default ProtectedRoute;
