import { createModel } from '@rematch/core';
import { RootModel } from 'global-store/models';

export type UnitType = string | undefined;
export type SelectedUnits = { parentId: string; unitType: UnitType; items: string[] };

const initialState: SelectedUnits = {
  parentId: '',
  unitType: undefined,
  items: [],
};

export const selectedUnits = createModel<RootModel>()({
  state: { ...initialState },
  reducers: {
    handleSelection: (state, payload: SelectedUnits) => ({ ...state, ...payload }),
    clearSelection: () => ({ ...initialState }),
  },
});
