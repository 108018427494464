const flattenArray = <T>(array: T[][]) => {
  const flattened: T[] = [];

  for (let i = 0; i < array.length; ++i) {
    flattened.push(...array[i]);
  }

  return flattened;
};

export default flattenArray;
