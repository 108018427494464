import { useQuery } from '@apollo/client';
import { GetSdkMessageTemplates } from 'global-graphql/__generated__/GetSdkMessageTemplates';
import { Replacements } from 'global-graphql/__generated__/Replacements';
import { queries } from 'global-graphql/queries';
import { FC } from 'react';
import ResolvedMessage from '../ResolvedMessage/ResolvedMessage';

type Props = {
  templateId: string;
  replacements: Replacements[];
  beforeOpen?: (target: HTMLElement, config: Replacements) => void;
};
const SdkMessage: FC<Props> = ({ templateId, replacements, beforeOpen }) => {
  const { data: templates } = useQuery<GetSdkMessageTemplates>(queries.getSkdMessageTemplates, {
    fetchPolicy: 'cache-first',
  });
  const template = templates?.sdkMessageTemplates.find((template) => template.id === templateId)?.template;
  if (!template) {
    return null;
  }
  return (
    <span>
      <ResolvedMessage template={template} replacements={replacements} beforeOpen={beforeOpen} />
    </span>
  );
};

export default SdkMessage;
