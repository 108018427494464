import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import Popover, { PopoverOrigin } from '@material-ui/core/Popover';
import { Component, ComponentType, Fragment, SyntheticEvent } from 'react';

export interface Props {
  popoverContent: ComponentType<{ closeActionsContent: () => void }>;
  iconRenderContainer?: ComponentType<Pick<IconButtonProps, 'buttonRef' | 'onClick'>>;
  iconRender: ComponentType<{}>;
  anchorOrigin?: PopoverOrigin;
}

type State = {
  showPopover: boolean;
  anchorEl?: HTMLElement;
};

export class ActionsPopover extends Component<Props, State> {
  state = {
    showPopover: false,
    anchorEl: undefined,
  };

  setAnchorEl = (node: HTMLElement) => {
    this.setState((state) => ({ ...state, anchorEl: node }));
  };

  showActionsContent = (e: SyntheticEvent<HTMLElement>) => {
    e.currentTarget.blur();

    this.setState((state) => ({ ...state, showPopover: true }));
  };

  closeActionsContent = () => {
    this.setState((state) => ({ ...state, showPopover: false }));
  };

  render() {
    const {
      iconRenderContainer: IconContainer = IconButton,
      iconRender: Icon,
      popoverContent: PopoverContent,
      anchorOrigin,
    } = this.props;

    const { showPopover, anchorEl } = this.state;

    return (
      <Fragment>
        <IconContainer buttonRef={this.setAnchorEl} onClick={this.showActionsContent}>
          <Icon />
        </IconContainer>
        <Popover
          open={showPopover}
          onClose={this.closeActionsContent}
          anchorEl={anchorEl}
          anchorOrigin={anchorOrigin}
          transformOrigin={anchorOrigin}
        >
          <PopoverContent closeActionsContent={this.closeActionsContent} />
        </Popover>
      </Fragment>
    );
  }
}

export default ActionsPopover;
