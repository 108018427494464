import { useQuery } from '@apollo/client';
import { RootNode } from 'global-graphql/__generated__/RootNode';
import { Subscriptions, SubscriptionsVariables } from 'global-graphql/__generated__/Subscriptions';
import { queries } from 'global-graphql/queries';
import nodeTypes from 'global-utils/node-types/node-types';

const useSubscriptionsQuery = () => {
  const { data: rootNodeData } = useQuery<RootNode>(queries.rootId);

  const companyId =
    rootNodeData && rootNodeData.root && rootNodeData.root.type.mainType === nodeTypes.company
      ? rootNodeData.root.id
      : null;

  const { data: subscriptionsData } = useQuery<Subscriptions, SubscriptionsVariables>(queries.subscriptions, {
    variables: { companyId: companyId! },
    skip: !companyId,
  });

  return subscriptionsData && subscriptionsData.subscriptions ? subscriptionsData.subscriptions : [];
};

export default useSubscriptionsQuery;
