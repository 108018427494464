import { Replacements_MonitoringJobLink } from 'global-graphql/__generated__/Replacements';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { AdditionalLinkProps } from '../../types';
import BrokenLink from '../BrokenLink/BrokenLink';
import MonitoringJobName from './components/ReportNameLink/MonitoringJobName';

type Props = Replacements_MonitoringJobLink & AdditionalLinkProps;

const MonitoringJobLink: FC<Props> = ({ fallbackText, job, ...additionalLinkProps }) => {
  const history = useHistory();
  if (!job?.id) {
    return <BrokenLink text={additionalLinkProps.customLabel || fallbackText} />;
  }

  const open = () => {
    history.push(`/monitoring/` + job.id);
  };

  return <MonitoringJobName {...additionalLinkProps} fallbackText={fallbackText} onClick={open} jobId={job.id} />;
};

export default MonitoringJobLink;
