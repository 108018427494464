import { useQuery } from '@apollo/client';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { ReportTemplates } from 'global-graphql/__generated__/ReportTemplates';
import { queries } from 'global-graphql/queries';
import { FC, MouseEventHandler } from 'react';
import { AdditionalLinkProps } from '../../../../types';
import BrokenLink from '../../../BrokenLink/BrokenLink';

type Props = AdditionalLinkProps & {
  fallbackText?: string | null;
  reportTemplateId: string;
  onClick: () => void;
};

const useStyles = makeStyles({
  link: { cursor: 'pointer' },
});

const ReportNameLink: FC<Props> = ({
  customLabel,
  fallbackText,
  onClick: onClickDelegate,
  beforeOpen,
  reportTemplateId,
}) => {
  const classes = useStyles();

  const { data, error } = useQuery<ReportTemplates>(queries.reportTemplates, { fetchPolicy: 'cache-first' });
  if (error) {
    return <BrokenLink text={customLabel || fallbackText || reportTemplateId} />;
  }
  const onClick: MouseEventHandler<HTMLElement> | undefined = onClickDelegate
    ? (e) => {
        if (beforeOpen) {
          beforeOpen(e.currentTarget);
        }
        onClickDelegate();
      }
    : undefined;
  const reportTemplate = data?.reportTemplates?.find((template) => template._id === reportTemplateId);
  return (
    <Link className={classes.link} onClick={onClick} role="link">
      {customLabel || reportTemplate?.fullName || reportTemplate?.shortName || reportTemplateId}
    </Link>
  );
};

export default ReportNameLink;
