import { useApolloClient } from '@apollo/client';
import { Notifications } from 'global-graphql/__generated__/Notifications';
import { queries } from 'global-graphql/queries';
import { useEffect, useState } from 'react';

const useNotificationsPolling = () => {
  const [notifications, setNotifications] = useState<Notifications['notifications']>([]);
  const client = useApolloClient();

  useEffect(() => {
    const subscription = client
      .watchQuery<Notifications>({ query: queries.notifications })
      .subscribe(({ data }) => {
        if (data) {
          setNotifications(data.notifications);
        } else {
          setNotifications([]);
        }
      });

    return () => {
      subscription.unsubscribe();
    };
  });

  useEffect(() => {
    const interval = setInterval(() => {
      client
        .query<Notifications>({
          query: queries.notifications,
          fetchPolicy: 'network-only',
        })
        .catch((error) => console.error(`Polling notifications failed. ${error.toString()}`));
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [client, setNotifications]);

  return notifications;
};

export default useNotificationsPolling;
