import { FC } from 'react';
import { GetCompensationItems_compensationItems_pricePerUnit } from '../../../../graphql/__generated__/GetCompensationItems';

const currencyFormatter = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

type Props = {
  price: GetCompensationItems_compensationItems_pricePerUnit;
  per?: string;
};

const Price: FC<Props> = ({ price: { value, currency }, per }) => (
  <>
    {currencyFormatter.format(value)} {currency}
    {per ? ` / ${per}` : null}
  </>
);

export default Price;
