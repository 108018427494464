import { Fragment, FunctionComponent } from 'react';

export interface Props {
  error: Error;
}

export const DefaultErrorHandler: FunctionComponent<Props> = ({ error }) => {
  return (
    <Fragment>
      <h1>Something went wrong.</h1>
      <p>{error.toString()}</p>
    </Fragment>
  );
};

export default DefaultErrorHandler;
