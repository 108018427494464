import { ApolloError } from '@apollo/client';
import Button from '@material-ui/core/Button/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Dispatch } from 'global-store/store';
import extractBadRequestMessage from 'global-utils/extractBadRequestMessage/extractBadRequestMessage';
import { Theme } from 'global-utils/uiThemeConfig';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(),
    width: '100%',
  },
  text: {
    paddingBottom: '1em',
  },
  emphasise: {
    color: theme.palette.error.main,
  },
}));

export interface Props {
  error: ApolloError;
  emphasise?: boolean;
  inline?: boolean;
}

interface Error {
  message: string;
  unknown: boolean;
  nodeId?: string;
}

export const ErrorInDataFetcher: FunctionComponent<Props> = ({ error: apolloError, emphasise, inline }) => {
  const { t } = useTranslation('widgets', { useSuspense: false });
  const classes = useStyles();
  const dispatch = useDispatch<Dispatch>();
  const [error, setError] = useState<Error | null>(null);

  const showNodeSettings = () => {
    if (error && error.nodeId) {
      dispatch.unitDialog.handleUnitDialogState({ dialogState: true, id: error.nodeId });
    }
  };

  useEffect(() => {
    const badRequestMessage = extractBadRequestMessage(apolloError);

    if (badRequestMessage) {
      setError({ message: badRequestMessage, unknown: false });
      return;
    }

    if (apolloError.networkError && apolloError.networkError instanceof Response) {
      apolloError.networkError
        .json()
        .then((result) => {
          if (Array.isArray(result.errors)) {
            if (result.errors.length === 1 && result.errors[0].status === 403) {
              setError({
                message: t('widgets:error-in-data-fetcher-forbidden'),
                unknown: false,
              });
            } else {
              setError({
                message: result.errors.map((error: ApolloError) => error.message).join(', '),
                unknown: true,
              });
            }
          } else {
            setError({
              message: JSON.stringify(result),
              unknown: true,
            });
          }
        })
        .catch((err) => setError(err.toString()));
    } else {
      const [firstError] = apolloError.graphQLErrors;
      if (
        firstError &&
        firstError.extensions &&
        firstError.extensions.exception &&
        firstError.extensions.exception.status === 422
      ) {
        setError({
          message: firstError.message,
          nodeId: firstError.extensions.exception.nodeId,
          unknown: false,
        });
      } else {
        setError({
          message: apolloError.graphQLErrors.map((error) => error.message).join(', '),
          unknown: true,
        });
      }
    }
  }, [apolloError, setError, t]);
  if (!error) {
    return null;
  }
  return (
    <div className={inline ? undefined : classes.container}>
      {error.unknown && (
        <>
          <Typography classes={{ root: classes.text }}>{t('widgets:error-in-data-fetcher')}</Typography>
          <Typography classes={{ root: classes.text }}>{t('widgets:error-in-data-fetcher-message')}</Typography>
        </>
      )}
      <Typography classes={{ root: inline ? '' : classes.text + (emphasise ? ` ${classes.emphasise}` : '') }}>
        {error.message}
        {error.nodeId && (
          <Button onClick={showNodeSettings} color="primary" data-test-type="widget-processing-error-navigate-to-node">
            {t('widgets:navigate-to-unit-details')}
          </Button>
        )}
      </Typography>
    </div>
  );
};

export default ErrorInDataFetcher;
