import { useQuery } from '@apollo/client';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import NotificationLevelSummary from 'global-components/containers/Notifications/components/NotificationPopup/components/NotificationLevelSummary/NotificationLevelSummary';
import { Notifications } from 'global-graphql/__generated__/Notifications';
import { NotificationTemplates } from 'global-graphql/__generated__/NotificationTemplates';
import { queries } from 'global-graphql/queries';
import flattenArray from 'global-utils/flattenArray/flattenArray';
import notEmpty from 'global-utils/notEmpty/notEmpty';
import { Theme } from 'global-utils/uiThemeConfig';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationGroup } from '../../../../../__generated__/globalTypes';
import GroupedNotifications from './components/GroupedNotifications/GroupedNotifications';
import groupNotifications from './utils/groupNotifications';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: '600vw',
    width: '90em',
  },
  title: {
    display: 'flex',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export type Props = {
  closeNotificationList: () => void;
};

export const NotificationPopup: FC<Props> = ({ closeNotificationList }) => {
  const { data: templatesData } = useQuery<NotificationTemplates>(queries.notificationTemplates, {
    fetchPolicy: 'cache-first',
  });
  const { data } = useQuery<Notifications>(queries.notifications, { fetchPolicy: 'cache-first' });
  const { t } = useTranslation(['global', 'notification'], { useSuspense: false });
  const classes = useStyles();

  const notificationTemplates = templatesData?.notificationTemplates;
  const notifications = data?.notifications;
  const groupedTemplates = useMemo(() => groupNotifications(notificationTemplates, notifications), [
    notificationTemplates,
    notifications,
  ]);

  const allTemplates = useMemo(() => flattenArray(Object.values(groupedTemplates).filter(notEmpty)), [
    groupedTemplates,
  ]);

  return (
    <div className={classes.container} data-test-type="notifications-popup">
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h6">{t('notification:list-heading')}</Typography>
        <NotificationLevelSummary groupedNotifications={allTemplates} />
        <IconButton
          className={classes.closeButton}
          onClick={closeNotificationList}
          data-test-type="close-notifications-popup"
          aria-label={t('global:close')}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <GroupedNotifications
          group={NotificationGroup.ERRORS}
          groupedNotifications={groupedTemplates[NotificationGroup.ERRORS]}
        />
        <GroupedNotifications
          group={NotificationGroup.MISSING_DATA}
          groupedNotifications={groupedTemplates[NotificationGroup.MISSING_DATA]}
        />
        <GroupedNotifications
          group={NotificationGroup.SUBSCRIPTIONS}
          groupedNotifications={groupedTemplates[NotificationGroup.SUBSCRIPTIONS]}
        />
        <GroupedNotifications
          group={NotificationGroup.REPORTS}
          groupedNotifications={groupedTemplates[NotificationGroup.REPORTS]}
        />
        <GroupedNotifications
          group={NotificationGroup.OTHERS}
          groupedNotifications={groupedTemplates[NotificationGroup.OTHERS]}
        />
        {(notifications?.length ?? 0) === 0 && t('notification:empty-list')}
      </DialogContent>
    </div>
  );
};

export default NotificationPopup;
