import { PermissionChecker } from '@ence/permission-facade';
import { StoreState } from 'global-store/store';
import { FC } from 'react';
import { useSelector } from 'react-redux';

export type AvailableChecks = keyof Omit<ReturnType<typeof PermissionChecker>, 'hasRole'>;
type Props = {
  /**
   * if any of the given checks applies, the children will be rendered
   */
  check: AvailableChecks | AvailableChecks[];
  /**
   * if the check should be negated
   */
  negate?: boolean;
};

const permissionCheckerSelector = (state: StoreState) => state.user.permissionChecker;
const HasPermission: FC<Props> = ({ check, negate = false, children }) => {
  const permissionChecker = useSelector(permissionCheckerSelector);
  const checks = Array.isArray(check) ? check : [check];
  let hasMatchingEntry = checks.some((test) => permissionChecker[test]());
  if (negate) {
    hasMatchingEntry = !hasMatchingEntry;
  }
  if (hasMatchingEntry) {
    return <>{children}</>;
  }
  return null;
};

export default HasPermission;
