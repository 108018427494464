import { useMutation, useQuery } from '@apollo/client';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { GetImpersonationStatusQueryResult, mutations, queries } from 'global-graphql/supportImpersonationQueries';
import { StoreState } from 'global-store/store';
import { Fragment, FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AccountDrawerItem from '../../../AccountDrawerItem/AccountDrawerItem';

export interface Props {}

const mutationOptions = { refetchQueries: [{ query: queries.getImpersonationStatus }] };

export const SupportItem: FunctionComponent<Props> = () => {
  const { t } = useTranslation('support');
  const [dialogOpen, setDialogOpen] = useState(false);
  const { data } = useQuery<GetImpersonationStatusQueryResult>(queries.getImpersonationStatus);
  const phoneNumber = useSelector<StoreState, string>((state) => state.environment.supportPhoneNumber);
  const [grantImpersonation] = useMutation(mutations.grantImpersonation, mutationOptions);
  const [revokeImpersonation] = useMutation(mutations.revokeImpersonation, mutationOptions);

  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  const isSupportImpersonationPossible = Boolean(data && data.supportImpersonationStatus);

  return (
    <Fragment>
      <AccountDrawerItem onClick={openDialog}>{t('support:support-menu-item')}</AccountDrawerItem>
      <Dialog open={dialogOpen} onClose={closeDialog}>
        <DialogTitle>{t('support:support-dialog-title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('support:support-dialog-content-phone', { replace: { phoneNumber } })}
          </DialogContentText>
          <DialogContentText>{t('support:support-dialog-content-impersonation')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>{t('support:support-dialog-close')}</Button>
          {isSupportImpersonationPossible ? (
            <Button color="secondary" onClick={() => revokeImpersonation()}>
              {t('support:support-dialog-revoke-impersonation-action')}
            </Button>
          ) : (
            <Button color="primary" onClick={() => grantImpersonation()}>
              {t('support:support-dialog-grant-impersonation-action')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default SupportItem;
