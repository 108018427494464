import flattenArray from 'global-utils/flattenArray/flattenArray';
import nodeTypes from 'global-utils/node-types/node-types';
import { CompensationMedium } from '../../../../../../../__generated__/globalTypes';
import {
  UnitNameAndType,
  UnitNameAndType_unit_children_items,
} from '../../../../../graphql/__generated__/UnitNameAndType';

const getMedium = (item: UnitNameAndType_unit_children_items): CompensationMedium | null => {
  const prop = flattenArray(item.properties.map((prop) => prop.items)).find(
    (prop) => prop.key === 'common:property-definition:co2-compensation-medium'
  );
  if (!prop) {
    return null;
  }
  switch (prop?.value?.[0]?.value?.[0]) {
    case 'common:option:co2-compensation-medium.fuel':
      return CompensationMedium.FUEL;
    case 'common:option:co2-compensation-medium.heat':
      return CompensationMedium.HEAT;
    case 'common:option:co2-compensation-medium.electricity':
      return CompensationMedium.ELECTRICITY;
    default:
      return null;
  }
};

const hasCompensationMeasurement = (data: UnitNameAndType, requiredMedium: CompensationMedium | null): boolean =>
  flattenArray(data.unit.children?.map(({ items }) => items) ?? []).find((item) => {
    return (
      item.type.mainType === nodeTypes.meter &&
      item.dataPoints?.find((dp) => dp.measurand.id === '7-145:66.23.0') &&
      requiredMedium === getMedium(item)
    );
  }) !== undefined;

export default hasCompensationMeasurement;
