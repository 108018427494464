import { Component, ComponentType } from 'react';
import DefaultErrorHandler from './components/DefaultErrorHandler/DefaultErrorHandler';

export interface Props<FallbackProps> {
  ErrorFallback?: ComponentType<{ error: Error } & FallbackProps>;
  fallbackProps?: FallbackProps;
}

type State = {
  error: Error | null;
};

export class ErrorBoundary<FallbackProps = {}> extends Component<Props<FallbackProps>, State> {
  state: State = {
    error: null,
  };

  static getDerivedStateFromError(error: Error): Partial<State> {
    return { error };
  }

  render() {
    const { children, ErrorFallback = DefaultErrorHandler, fallbackProps } = this.props;
    const { error } = this.state;

    return error ? <ErrorFallback error={error} {...fallbackProps!} /> : children;
  }
}

export default ErrorBoundary;
