import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import { Theme } from 'global-utils/uiThemeConfig';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageLevel } from '../../../../../../../__generated__/globalTypes';
import { NotificationsOfTemplate } from '../../utils/groupNotifications';

const useStyles = makeStyles((theme: Theme) => ({
  info: {
    color: theme.palette.info.light,
  },
  warning: {
    color: theme.palette.warning.light,
  },
  error: {
    color: theme.palette.error.light,
  },
  state: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    color: theme.palette.text.secondary,
  },
}));

type GroupInfo = {
  info: number;
  warning: number;
  error: number;
};

type Props = {
  groupedNotifications?: NotificationsOfTemplate[];
};

const NotificationLevelSummary: FC<Props> = ({ groupedNotifications = [] }) => {
  const classes = useStyles();
  const { t } = useTranslation('notification', { useSuspense: false });

  const groupInfo = useMemo(() => {
    const result: GroupInfo = { info: 0, warning: 0, error: 0 };
    groupedNotifications?.forEach(({ template: { level }, notifications: { length: count } }) => {
      switch (level) {
        case MessageLevel.ERROR:
          result.error += count;
          break;
        case MessageLevel.INFO:
          result.info += count;
          break;
        case MessageLevel.WARNING:
          result.warning += count;
          break;
      }
    });
    return result;
  }, [groupedNotifications]);

  return (
    <>
      {groupInfo.error > 0 && (
        <Tooltip title={t('notification:error')!}>
          <div key="error" className={classes.state}>
            <ErrorIcon className={classes.error} fontSize={'inherit'} />
            <span>{groupInfo.error}</span>
          </div>
        </Tooltip>
      )}
      {groupInfo.warning > 0 && (
        <Tooltip title={t('notification:warning')!}>
          <div key="warning" className={classes.state}>
            <WarningIcon className={classes.warning} fontSize={'inherit'} />
            <span>{groupInfo.warning}</span>
          </div>
        </Tooltip>
      )}
      {groupInfo.info > 0 && (
        <Tooltip title={t('notification:info')!}>
          <div key="info" className={classes.state}>
            <InfoIcon className={classes.info} fontSize={'inherit'} />
            <span>{groupInfo.info}</span>
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default NotificationLevelSummary;
