import { Models } from '@rematch/core';
import { credentialManagementDialog } from 'global-screens/CredentialManagement/models/credentialManagementModel/credentialManagement.model';
import { dashboardSelectionDialog } from 'global-screens/Dashboard/models/dashboardSelectionDialog.model';
import { dashboardTileConfiguration } from 'global-screens/Dashboard/models/tileConfigurationDialog.model';
import { dashboardTileMaximizeConfiguration } from 'global-screens/Dashboard/models/tileMaximizeState.model';
import { tariffDetailsDialog } from 'global-screens/TariffDetails/models/tariffDetailsDialogModel/tariffDetailsDialog.model';
import { balanceVisualization } from '../screens/BalanceVisualization/models/selectedVisualizationEditor.model';
import { importExportDialog } from '../screens/ImportExport/models/importExportModel/importExport.model';
import { manualMeasurementDataInputDialog } from '../screens/ManualMeasurementDataInput/models/manualMeasurementDataInputModel/manualMeasurementDataInput.model';
import { measurandWhitelistDialog } from '../screens/MeasurandWhitelist/models/measurandWhitelist/measurandWhitelist.model';
import { unassignedMeters } from '../screens/MeterAssignment/models/unassignedMeters.model';
import { reportDetails } from '../screens/ReportDetails/models/reportDetails.model';
import { reportsList } from '../screens/Reports/models/reportsListModel/reportsListModel';
import { subscriptionManagement } from '../screens/SubscriptionManagement/models/subscriptionManagementModel/subscriptionManagement.model';
import { subscriptionStatistics } from '../screens/SubscriptionStatistics/models/subscriptionStatisticsModel/subscriptionStatistics.model';
import { unitDialog } from '../screens/UnitDetails/models/unitDetailsModel/unitDetails.model';
import { selectedUnits } from '../screens/Units/models/selectedUnits.model';
import { usersDialog } from '../screens/Users/models/usersDialog/users.model';
import { environment } from './models/environment';
import { notification } from './models/notification.model';
import { user } from './models/user.model';

export interface RootModel extends Models<RootModel> {
  environment: typeof environment;
  user: typeof user;
  selectedUnits: typeof selectedUnits;
  unitDialog: typeof unitDialog;
  manualMeasurementDataInputDialog: typeof manualMeasurementDataInputDialog;
  reportsList: typeof reportsList;
  notification: typeof notification;
  unassignedMeters: typeof unassignedMeters;
  balanceVisualization: typeof balanceVisualization;
  subscriptionStatistics: typeof subscriptionStatistics;
  subscriptionManagement: typeof subscriptionManagement;
  reportDetails: typeof reportDetails;
  importExportDialog: typeof importExportDialog;
  usersDialog: typeof usersDialog;
  measurandWhitelistDialog: typeof measurandWhitelistDialog;
  credentialManagementDialog: typeof credentialManagementDialog;
  dashboardTileConfiguration: typeof dashboardTileConfiguration;
  dashboardTileMaximizeConfiguration: typeof dashboardTileMaximizeConfiguration;
  dashboardSelectionDialog: typeof dashboardSelectionDialog;
  tariffDetailsDialog: typeof tariffDetailsDialog;
}

export const models: RootModel = {
  environment,
  user,
  selectedUnits,
  unitDialog,
  manualMeasurementDataInputDialog,
  reportsList,
  notification,
  unassignedMeters,
  balanceVisualization,
  subscriptionStatistics,
  subscriptionManagement,
  reportDetails,
  importExportDialog,
  usersDialog,
  measurandWhitelistDialog,
  credentialManagementDialog,
  dashboardTileConfiguration,
  dashboardTileMaximizeConfiguration,
  dashboardSelectionDialog,
  tariffDetailsDialog,
};
