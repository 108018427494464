import { useQuery } from '@apollo/client';
import { UnitTypes } from 'global-graphql/__generated__/UnitTypes';
import { queries } from 'global-graphql/queries';
import { useCallback } from 'react';

const useGetUnitTypeName = () => {
  const { data } = useQuery<UnitTypes>(queries.unitTypes);
  return useCallback(
    (unitMainType: string) =>
      data?.unitTypes?.find((unitType) => unitType.mainType === unitMainType)?.name ?? unitMainType,
    [data]
  );
};

export default useGetUnitTypeName;
