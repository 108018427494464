import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { ComponentProps, FC } from 'react';
import HasPermission, { AvailableChecks } from '../../../../presentational/HasPermission/HasPermission';

const useStyles = makeStyles({
  menuItem: {
    paddingLeft: 30,
    paddingRight: 30,
  },
});

type Props = ComponentProps<typeof MenuItem> & {
  'data-test-type'?: string;
  check?: AvailableChecks | AvailableChecks[];
};

export const AccountDrawerItem: FC<Props> = ({
  'data-test-type': dataTestType = 'account-drawer-item',
  check,
  ...props
}) => {
  const classes = useStyles();

  const Component = () => <MenuItem {...props} className={classes.menuItem} data-test-type={dataTestType} />;
  return check !== undefined ? (
    <HasPermission check={check}>
      <Component />
    </HasPermission>
  ) : (
    <Component />
  );
};

export default AccountDrawerItem;
