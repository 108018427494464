import { fragments as globalFragments } from 'global-graphql/queries';
import gql from 'graphql-tag';

export const queries = {
  ancestors: gql`
    query Ancestors($id: ID!) {
      unit(id: $id) {
        id
        ancestors {
          id
          parent {
            id
          }
        }
      }
    }
  `,
  rootNode: gql`
    query UnitsRootNode {
      root {
        id
        type {
          mainType
        }
      }
    }
  `,
  unitTypesMeta: gql`
    query UnitTypesMeta {
      unitTypes {
        _id
        mainType
        validChildren {
          _id
          mainType
          name
        }
      }
      measurementSources {
        id
        isCreatedManually
      }
    }
  `,
  pathToUnit: gql`
    query UnitsPathToUnit($id: ID!) {
      unit(id: $id) {
        id

        ...PathToUnit
      }
    }

    ${globalFragments.pathToUnit}
  `,
  unassignedMeters: gql`
    query UnassignedMeters {
      root {
        id
        unassignedMeters {
          id
        }
      }
    }
  `,
};
