import { DataPointInput } from '@ence/apis-graphql';
import SingleDataPointSelection, {
  SingleDataPointSelectionProps,
} from 'global-components/widget-delegates/SingleDataPointSelection';
import { showErrorOnChange } from 'mui-rff';
import { FC } from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';

const SingleDataPointSelectionWrapper: FC<FieldRenderProps<DataPointInput>> = ({
  input: { value, onChange },
  meta,
  required,
  fullWidth = true,
  helperText,
  showError = showErrorOnChange,
  ...rest
}) => {
  return (
    <div style={{ marginLeft: -8 }}>
      <SingleDataPointSelection setSelectedDatapoint={onChange} selectedDataPoint={value} {...rest} />
    </div>
  );
};

const DataPointSelectionField: FC<Omit<SingleDataPointSelectionProps, 'setSelectedDatapoint' | 'selectedDataPoint'> & {
  name: string;
  fieldProps?: Partial<FieldProps<any, any>>;
}> = ({ name, fieldProps, ...rest }) => (
  <Field
    name={name}
    format={(value) => (value === null || value === '' ? undefined : value)}
    render={({ input, meta }) => <SingleDataPointSelectionWrapper input={input} meta={meta} {...rest} />}
    {...fieldProps}
  />
);

export default DataPointSelectionField;
