import { MutableRefObject, useEffect, useState } from 'react';

const useIsVisible = (elementRef: MutableRefObject<any>) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (elementRef.current) {
      const observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsVisible(true);
              observer.unobserve(elementRef.current);
            }
          });
        },
        {
          root: null,
          rootMargin: '0px 0px 0px 0px',
          threshold: 0,
        }
      );
      observer.observe(elementRef.current);
    }
  }, [elementRef]);

  return isVisible;
};

export default useIsVisible;
