import { createModel } from '@rematch/core';
import { RootModel } from 'global-store/models';

export type UsersStateModel = {
  dialogState: boolean;
  companyId: string | null;
};

export const usersDialog = createModel<RootModel>()({
  state: { dialogState: false, companyId: null } as UsersStateModel,
  reducers: {
    handleUserActionsDialogState: (state, payload: UsersStateModel) => ({ ...state, ...payload }),
  },
});
