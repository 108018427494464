import { Replacements_BalanceVisualizationLink } from 'global-graphql/__generated__/Replacements';
import { Dispatch } from 'global-store/store';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AdditionalLinkProps } from '../../types';
import BrokenLink from '../BrokenLink/BrokenLink';
import ResolvedUnitNameLink from '../ResolvedUnitNameLink/ResolvedUnitNameLink';

type Props = Replacements_BalanceVisualizationLink & AdditionalLinkProps;

const BalanceVisualizationLink: FC<Props> = ({ fallbackText, balanceVisualizationNode, ...additionalLinkProps }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();

  if (!balanceVisualizationNode) {
    return <BrokenLink text={additionalLinkProps.customLabel || fallbackText} />;
  }

  const nodeId = balanceVisualizationNode.id;
  const path = `/units/${nodeId}?balanceVisualization=${nodeId}`;
  const open = () => {
    if (location.pathname.startsWith('/units')) {
      dispatch.balanceVisualization.handleBalanceVisualizationDialogState({ dialogState: true, unitId: nodeId });
    } else {
      history.push(path);
    }
  };
  return (
    <ResolvedUnitNameLink
      {...additionalLinkProps}
      fallbackText={fallbackText}
      onClick={open}
      nodeId={nodeId}
      href={path}
    />
  );
};

export default BalanceVisualizationLink;
