import { TranslateMethod } from 'global-typings/i18next';
import translate from 'global-utils/translate/translate';
import { FunctionComponent } from 'react';

interface Props {
  t: TranslateMethod;
}

export const LoadingScreenPlaceholder: FunctionComponent<Props> = ({ t }) => <div>{t('global:loading-screen')}</div>;

export default translate('global')(LoadingScreenPlaceholder);
