import { Theme as MUITheme, ThemeOptions as MUIThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import {
  Palette as MUIPalette,
  PaletteColor,
  PaletteColorOptions,
  PaletteOptions as MUIPaletteOptions,
} from '@material-ui/core/styles/createPalette';

interface Palette extends MUIPalette {
  warning: PaletteColor;
}

export interface Theme extends MUITheme {
  palette: Palette;
}

interface PaletteOptions extends MUIPaletteOptions {
  warning: PaletteColorOptions;
}

interface ThemeOptions extends MUIThemeOptions {
  palette?: PaletteOptions;
}

export const themeColors = {
  lightBlue: '#00AAE1',
  darkBlue: '#143c8c',
  lightGreen: '#64B42D',
  darkGreen: '#008732',
  red: '#BD0043',
  orange: '#F59B00',
  primaryGray: '#3C3732',
  secondaryGray: '#8A8784',
  dividerGray: '#D7D6D5',
  disabledGray: '#EBEAEA',
  defaultGray: '#f4f4f4',
  white: '#fff',
};

const theme: ThemeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: themeColors.lightBlue,
      dark: themeColors.darkBlue,
      contrastText: themeColors.white,
    },
    secondary: {
      main: themeColors.lightGreen,
      dark: themeColors.darkGreen,
      contrastText: themeColors.white,
    },
    error: {
      main: themeColors.red,
      contrastText: themeColors.white,
    },
    warning: {
      main: themeColors.orange,
    },
    divider: themeColors.dividerGray,
    background: {
      paper: themeColors.white,
      default: themeColors.defaultGray,
    },
    text: {
      primary: themeColors.primaryGray,
      secondary: themeColors.secondaryGray,
      disabled: themeColors.disabledGray,
      hint: themeColors.dividerGray,
    },
    action: {
      disabled: themeColors.secondaryGray,
    },
  },

  typography: {
    fontFamily: 'innogy-Medium, Tahoma, "Helvetica Neue", Helvetica, Arial, sans-serif',
    htmlFontSize: 16,
  },

  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: themeColors.white,
        color: themeColors.primaryGray,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: themeColors.dividerGray,
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: 24,
      },
    },
  },
};

export default theme;
