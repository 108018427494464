import { Dispatch, StoreState } from 'global-store/store';
import { UpdateReduxConfig, UpdateUrlConfig } from 'global-utils/savePopupScreenStateInURL/savePopupScreenStateInURL';

const popupUrlConfigWithId = (
  urlSearchParamName: string,
  openScreen: (dispatch: Dispatch, id: string) => void,
  getState: (state: StoreState) => { id: string | null; open: boolean }
) => {
  type ExtractedStoreState = { id: string | null; open: boolean };

  const updateReduxOnMount = ({ location, dispatch }: UpdateReduxConfig) => {
    const urlSearchParams = new URLSearchParams(location.search);

    if (urlSearchParams.has(urlSearchParamName)) {
      openScreen(dispatch, urlSearchParams.get(urlSearchParamName) || '');
    }
  };

  const updateURLOnReduxChange = ({ state, history, location }: UpdateUrlConfig<ExtractedStoreState>) => {
    const urlSearchParams = new URLSearchParams(location.search);

    if (!state.open && urlSearchParams.has(urlSearchParamName)) {
      urlSearchParams.delete(urlSearchParamName);
    } else if (
      state.open &&
      state.id &&
      (!urlSearchParams.has(urlSearchParamName) || urlSearchParams.get(urlSearchParamName) !== state.id)
    ) {
      urlSearchParams.set(urlSearchParamName, state.id);
    } else {
      // don't push history unnecessarily often
      return;
    }

    const searchParamsString = urlSearchParams.toString();

    history.push(location.pathname + (searchParamsString.length ? '?' + searchParamsString : ''));
  };

  return { updateReduxOnMount, extractStateFromRedux: getState, updateURLOnReduxChange };
};

export default popupUrlConfigWithId;
