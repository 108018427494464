import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Trash from '@material-ui/icons/Delete';
import { Notifications_notifications } from 'global-graphql/__generated__/Notifications';
import { NotificationTemplates_notificationTemplates } from 'global-graphql/__generated__/NotificationTemplates';
import { Theme } from 'global-utils/uiThemeConfig';
import moment from 'moment';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HasPermission from '../../../../../../../../../../presentational/HasPermission/HasPermission';
import ResolvedMessage from '../../../../../../../../../ResolvedMessage/ResolvedMessage';
import useDeleteNotification from './hooks/useDeleteNotification/useDeleteNotification';

const useStyles = makeStyles((theme: Theme) => {
  const iconContainerScale = 0.75;

  return {
    container: {
      alignItems: 'start',
      display: 'flex',
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
    },
    text: {
      wordBreak: 'break-word',
      fontSize: theme.typography.body1.fontSize,
    },
    iconContainer: {
      whiteSpace: 'nowrap',
      position: 'static',
      transform: `scale(${iconContainerScale})`,
    },
  };
});

type Props = {
  template: NotificationTemplates_notificationTemplates;
  notification: Notifications_notifications;
};

const NotificationItem: FC<Props> = ({ template, notification }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const deleteNotificationMutation = useDeleteNotification(notification.id);

  const { t } = useTranslation('notification', { useSuspense: false });
  const classes = useStyles();

  const deleteNotification = async () => {
    if (isDeleting) return;

    setIsDeleting(true);

    try {
      await deleteNotificationMutation();
    } finally {
      setIsDeleting(false);
    }
  };
  return (
    <ListItem ContainerProps={{ className: classes.container }} alignItems="flex-start">
      <ListItemText
        primary={<ResolvedMessage template={template.template} replacements={notification.replacements} />}
        secondary={
          <span title={moment(notification.createdAt).format('LL LT')}>{moment(notification.createdAt).fromNow()}</span>
        }
        classes={{ primary: classes.text }}
      />
      <HasPermission check={'canDeleteNotifications'}>
        <ListItemSecondaryAction classes={{ root: classes.iconContainer }}>
          <Tooltip title={t('notification:delete-notification')!}>
            <IconButton onClick={deleteNotification} data-test-type="delete-notification">
              {!isDeleting ? <Trash /> : <CircularProgress size={24} />}
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </HasPermission>
    </ListItem>
  );
};

export default NotificationItem;
