import MomentUtils from '@date-io/moment';
import { createMuiTheme } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { StylesProvider, ThemeProvider } from '@material-ui/styles';
import AuthenticationProvider from 'global-components/containers/AuthenticationProvider/AuthenticationProvider';
import ErrorBoundary from 'global-components/containers/ErrorBoundary/ErrorBoundary';
import SnackbarProvider from 'global-components/containers/SnackbarProvider/SnackbarProvider';
import RenderForbiddenInfoFallback from 'global-utils/RenderForbiddenInfoFallback/RenderForbiddenInfoFallback';
import { render } from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import i18n from './i18n';
import './index.css';
import './initialize';
import { store } from './store/store';
import uiThemeConfig from './utils/uiThemeConfig';

const theme = createMuiTheme(uiThemeConfig);
const rootElement = document.getElementById('root');

render(
  <ThemeProvider theme={theme}>
    <StylesProvider injectFirst>
      <CssBaseline />

      <Provider store={store}>
        <Router>
          <I18nextProvider i18n={i18n}>
            <SnackbarProvider>
              <RenderForbiddenInfoFallback>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <ErrorBoundary>
                    <AuthenticationProvider>
                      <App />
                    </AuthenticationProvider>
                  </ErrorBoundary>
                </MuiPickersUtilsProvider>
              </RenderForbiddenInfoFallback>
            </SnackbarProvider>
          </I18nextProvider>
        </Router>
      </Provider>
    </StylesProvider>
  </ThemeProvider>,
  rootElement
);
