import ExpansionPanel from '@material-ui/core/ExpansionPanel/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NotificationLevelSummary from 'global-components/containers/Notifications/components/NotificationPopup/components/NotificationLevelSummary/NotificationLevelSummary';
import { NotificationsOfTemplate } from 'global-components/containers/Notifications/components/NotificationPopup/utils/groupNotifications';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationGroup } from '../../../../../../../__generated__/globalTypes';
import NotificationTemplateGroup from './components/NotificationTemplateGroup/NotificationTemplateGroup';

const useStyles = makeStyles({
  panel: {
    width: '100%',
  },
});

type Props = {
  group: NotificationGroup;
  groupedNotifications?: NotificationsOfTemplate[];
};

const GroupedNotifications: FC<Props> = ({ group, groupedNotifications }) => {
  const classes = useStyles();
  const { t } = useTranslation('notification', { useSuspense: false });
  if (!groupedNotifications?.find(({ notifications: { length } }) => length > 0)) {
    return null;
  }
  return (
    <ExpansionPanel TransitionProps={{ timeout: 0 }} data-test-type="notification-group">
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${group}-content`}
        id={`${group}-header`}
        data-test-type="notification-group-header"
      >
        <Typography>{t(`groups.${group}`)}</Typography>
        <NotificationLevelSummary groupedNotifications={groupedNotifications} />
      </ExpansionPanelSummary>
      <ExpansionPanelDetails data-test-type="notification-group-body">
        <div className={classes.panel}>
          {groupedNotifications?.map(({ template, notifications }) => (
            <NotificationTemplateGroup key={template.id} template={template} notifications={notifications} />
          ))}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default GroupedNotifications;
