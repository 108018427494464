import { useQuery } from '@apollo/client';
import { ProcessResult } from 'global-graphql/__generated__/ProcessResult';
import { WidgetComputeResult, WidgetComputeResultVariables } from 'global-graphql/__generated__/WidgetComputeResult';
import { queries } from 'global-graphql/queries';
import { useMemo, useState } from 'react';
import useProcessResultHandler from './useProcessResultHandler';

type Props = {
  widgetId: string;
  showErrorInSnackbar?: boolean;
  skip?: boolean;
  config: Record<string, any> | null;
};
const useWidgetComputeResult = <T extends unknown>({ widgetId, config, skip, showErrorInSnackbar }: Props) => {
  const [processResult, setProcessResult] = useState<ProcessResult | null | undefined>(undefined);
  const processResultHandler = useProcessResultHandler({ showErrorInSnackbar });
  const configuration = config
    ? Object.entries(config).map(([key, value]) => ({
        key,
        value: JSON.stringify(value ?? null),
      }))
    : null;
  const { error, loading } = useQuery<WidgetComputeResult, WidgetComputeResultVariables>(queries.widgetComputeResult, {
    variables: {
      widgetId,
      configuration: configuration!,
    },
    skip: !widgetId || !configuration || skip === true,
    onCompleted: (data) => setProcessResult(data.widgetComputeResult),
  });
  const handledResult = useMemo(
    () => processResultHandler(processResult, error),
    [processResult, error, processResultHandler]
  );
  return {
    error: handledResult?.error ?? error,
    loading,
    result: handledResult?.result as T | undefined,
    processingError: handledResult?.processError,
  };
};

export default useWidgetComputeResult;
