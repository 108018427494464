import { Replacements } from 'global-graphql/__generated__/Replacements';
import { FC, Fragment } from 'react';
import Replacement from './components/Replacement/Replacement';

type Props = {
  template: string;
  replacements: Replacements[];
  beforeOpen?: (target: HTMLElement, config: Replacements) => void;
};

const ResolvedMessage: FC<Props> = ({ template, replacements, beforeOpen: beforeOpenDelegate }) => {
  // noinspection RegExpRedundantEscape
  const regExp = /\{(?<key>[a-zA-Z][\w-]+)(?::\[(?<customLabel>[^\]]+)])?}/g;
  let matches: RegExpExecArray | null;
  let pos = 0;
  const result: React.ReactNode[] = [];
  while ((matches = regExp.exec(template))) {
    // @ts-ignore
    const { key, customLabel } = matches.groups;
    if (matches.index > pos) {
      const text = template.substr(pos, matches.index - pos);
      result.push(<Fragment key={pos}>{text}</Fragment>);
    }
    const config = replacements.find((value) => value.placeholder === key);

    const beforeOpen =
      config && beforeOpenDelegate
        ? (target: HTMLElement) => {
            beforeOpenDelegate(target, config);
          }
        : undefined;

    let replacement = config ? (
      <Replacement key={matches.index} customLabel={customLabel} config={config} beforeOpen={beforeOpen} />
    ) : null;
    if (!replacement) {
      replacement = <Fragment key={matches.index}>{customLabel ?? key}</Fragment>;
    }
    result.push(replacement);

    pos = matches.index + matches[0].length;
  }
  result.push(<Fragment key={pos}>{template.substr(pos)}</Fragment>);
  return <>{result}</>;
};

export default ResolvedMessage;
