import { store } from 'global-store/store';
import portableFetch from 'portable-fetch';

const patchHeaders = (init: RequestInit = {}): RequestInit => {
  return {
    ...init,
    headers: {
      ...(init.headers ? init.headers : {}),
      authorization: 'bearer ' + store.getState().user.accessToken,
      'accept-language': 'de-de,de',
    },
  };
};

/**
 * fetch like interface that adds authorization and accept-language header automatically
 * refetches the access token automatically retries if the response is 401 (up to two times)
 * @param url
 * @param init
 */
const fetch = async (url: RequestInfo, init?: RequestInit): Promise<Response> => {
  let retries = 2;
  let error: any;
  if (!store.getState().user.accessToken) {
    await store.dispatch.user.updateAccessToken();
  }

  while (retries > 0) {
    try {
      return await portableFetch(url, patchHeaders(init)).then((response) => {
        if (!response.ok) throw response;
        return response;
      });
    } catch (e) {
      error = e;

      if (e.status === 401) {
        await store.dispatch.user.updateAccessToken();

        retries--;
      } else {
        retries = 0;
      }
    }
  }

  throw error;
};

export default fetch;
