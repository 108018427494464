import KpiDonut from 'global-components/widget-delegates/KpiDonut';
import { FC } from 'react';

type Props = Omit<React.ComponentProps<typeof KpiDonut>, 'kpi'>;
const DonutCharts: FC<Props> = (props) => {
  return (
    <>
      <KpiDonut kpi="CO2_EMISSION" {...props} />
      <KpiDonut kpi="CO2_EMISSION_PER_MEDIUM" {...props} />
      <KpiDonut kpi="CO2_COMPENSATION_ALL" {...props} />
      <KpiDonut kpi="CO2_COMPENSATION_FUEL" {...props} />
      <KpiDonut kpi="CO2_COMPENSATION_HEAT" {...props} />
      <KpiDonut kpi="CO2_COMPENSATION_ELECTRICITY" {...props} />
      <KpiDonut kpi="CO2_COMPENSATION_UNCLASSIFIED" {...props} />
    </>
  );
};

export default DonutCharts;
