import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import SettingsIcon from '@material-ui/icons/Settings';
import { StoreState } from 'global-store/store';
import { FC, Fragment, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AccountDrawerItem from '../../AccountDrawerItem/AccountDrawerItem';

export const muiItemStyle = {
  paddingLeft: 30,
  paddingRight: 30,
};

const useStyles = makeStyles((theme) => ({
  accountDrawer: {
    minWidth: '300px',
  },
  header: {
    padding: '15px 30px',
    marginRight: theme.spacing(4),
  },
  settings: {
    position: 'absolute',
    right: theme.spacing(3),
    top: 0,
  },
  menuItem: muiItemStyle,
  versionItem: {
    fontSize: '0.8em',
    marginTop: '3em',

    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'auto',
    },

    '& > span': {
      display: 'none',
    },
  },
}));

const userStateSelector = (state: StoreState) => ({
  userProfile: state.user.userProfile,
  logout: state.user.logout,
  accountSettingsURL: state.user.accountSettingsURL,
  version: state.environment.version,
});

const AccountItems: FC = ({ children }) => {
  const { userProfile, logout, accountSettingsURL, version } = useSelector(userStateSelector);
  const fullName = `${userProfile.firstName} ${userProfile.lastName}`;
  const { t } = useTranslation('global', { useSuspense: false });
  const classes = useStyles();

  return (
    <Suspense fallback={<CircularProgress size={30} />}>
      <div className={classes.accountDrawer}>
        <header className={classes.header}>
          <span
            dangerouslySetInnerHTML={{
              __html: t('global:welcome', {
                replace: { userName: `<strong data-test-type="full-name">${fullName}</strong>` },
                interpolation: { escapeValue: false },
              }),
            }}
          />
          {accountSettingsURL && (
            <div className={classes.settings}>
              <Tooltip title={t('global:account-keycloak-settings')!}>
                <IconButton edge={'end'} href={accountSettingsURL} target="_blank">
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </header>

        <Divider />

        {children && (
          <Fragment>
            {children}
            <Divider />
          </Fragment>
        )}

        <AccountDrawerItem onClick={logout}>{t('global:account-drawer-logout')}</AccountDrawerItem>
        <MenuItem className={`${classes.menuItem} ${classes.versionItem}`}>{`connect enlight v${version}`}</MenuItem>
      </div>
    </Suspense>
  );
};

export default AccountItems;
