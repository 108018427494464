import { createModel } from '@rematch/core';
import { RootModel } from 'global-store/models';

export type BalanceVisualizationState = {
  dialogState: boolean;
  unitId: string;
};

const initialState: BalanceVisualizationState = { dialogState: false, unitId: '' };

export const balanceVisualization = createModel<RootModel>()({
  state: { ...initialState },
  reducers: {
    handleBalanceVisualizationDialogState: (state, payload: BalanceVisualizationState) => ({ ...state, ...payload }),
    clearBalanceVisualizationSelection: () => ({ ...initialState }),
  },
});
