import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { style } from 'global-screens/Units/styles/style';
import { SnackbarProvider as NotistackSnackbarProvider, useSnackbar } from 'notistack';
import { FunctionComponent } from 'react';

const useStyles = makeStyles((theme) => ({
  ...style,
  info: {
    backgroundColor: theme.palette.info.light,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
}));

const Action = ({ snackBarIdentifier }: { snackBarIdentifier: string | number | undefined }) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton color="inherit" className={classes.buttonItem} onClick={() => closeSnackbar(snackBarIdentifier)}>
      <CloseIcon className={classes.iconButton} />
    </IconButton>
  );
};

export const SnackbarProvider: FunctionComponent = ({ children }) => {
  const classes = useStyles();
  return (
    <NotistackSnackbarProvider
      maxSnack={3}
      anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      action={(key) => <Action snackBarIdentifier={key} />}
      children={children}
      data-test-type="snackbar"
      classes={{
        variantInfo: classes.info,
        variantError: classes.error,
      }}
    />
  );
};

export default SnackbarProvider;
