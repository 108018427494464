import { useQuery } from '@apollo/client';
import { DataPointInput } from '@ence/apis-graphql';
import { MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import ArrowForward from '@material-ui/icons/ArrowForward';
import HasPermission from 'global-components/presentational/HasPermission/HasPermission';
import NumberInputField from 'global-components/presentational/NumberInputField/NumberInputField';
import useGetUnitTypeName from 'global-hooks/useGetUnitTypeName';
import numberFormatter from 'global-utils/numberFormatter/numberFormatter';
import { requiredField } from 'global-utils/required-field/required-field';
import moment from 'moment';
import { Checkboxes, Select } from 'mui-rff';
import { FC, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { CompensationMedium } from '../../../../../../__generated__/globalTypes';
import { GetCompensationItems_compensationItems } from '../../../../graphql/__generated__/GetCompensationItems';
import { UnitNameAndType, UnitNameAndTypeVariables } from '../../../../graphql/__generated__/UnitNameAndType';
import { queries } from '../../../../graphql/queries';
import { TIME_FORMAT, UNIT_CO2 } from '../../../../utils/constants';
import AmountCO2 from '../AmountCO2/AmountCO2';
import Price from '../Price/Price';
import DataPointSelectionField from './components/DataPointSelectionField';
import CompensationPurchaseConfirmation, {
  CompensationPurchaseConfirmationProps,
} from './presentational/CompensationPurchaseConfirmation';
import hasCompensationMeasurement from './utils/hasCompensationMeasurement';

const useStyles = makeStyles((theme) => ({
  detailTable: {
    '& tr > th': {
      paddingLeft: 0,
    },
  },
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(3),
  },
  arrowIcon: {
    margin: '0 0.75em',
  },
  dateRange: {
    display: 'flex',
    alignItems: 'center',
  },
  dropdown: {
    width: '100%',
  },
}));

type Props = {
  item: GetCompensationItems_compensationItems;
  nodeId: string;
  startDate: string;
  endDate: string;
};

type FormData = {
  quantity: number;
  writeTimeSeries: boolean;
  medium: CompensationMedium | null;
  weighted: boolean;
  weightedBy: DataPointInput | null;
};

const CompensationItemDetails: FC<Props> = ({ item, nodeId, startDate, endDate }) => {
  const classes = useStyles();
  const getUnitTypeName = useGetUnitTypeName();
  const { t } = useTranslation('compensation');
  const { data } = useQuery<UnitNameAndType, UnitNameAndTypeVariables>(queries.unitNameAndType, {
    variables: { id: nodeId },
  });
  const [confirmationProperties, setConfirmationProperties] = useState<Omit<
    CompensationPurchaseConfirmationProps,
    'onClose'
  > | null>(null);
  if (data == null) {
    return null;
  }
  const startTime = moment(startDate);
  // end + 1 day since we want to have the values distributed over the whole last day as well
  const endTime = moment(endDate).add(1, 'day');

  const onSubmit: (data: FormData) => void = ({ medium, weightedBy, quantity, writeTimeSeries, weighted }) => {
    setConfirmationProperties({
      item,
      startDate: startTime,
      endDate: endTime,
      quantity,
      writeTimeSeries,
      nodeId,
      medium,
      weightedBy: weighted ? weightedBy : null,
      newDatapoint: !hasCompensationMeasurement(data, medium),
      nodeTypeName: getUnitTypeName(data.unit.type.mainType),
      nodeName: data.unit.name,
      token: uuid(),
    });
  };

  const minQuantity = item.minQuantity ?? 1;
  const initialValues: Partial<FormData> = {
    writeTimeSeries: true,
    quantity: minQuantity,
    weighted: false,
    medium: null,
    weightedBy: null,
  };

  return (
    <>
      <Form<FormData>
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, valid }) => (
          <Card role="region" aria-labelledby="details-header">
            <CardContent>
              <Typography variant="h5" component="h2" id="details-header">
                {item.name}
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                {item.supplier.name}
              </Typography>
              {item.description && item.description.length && <Typography>{item.description}</Typography>}
              <hr />

              <Table className={classes.detailTable} size={'small'}>
                <TableBody>
                  {minQuantity && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {t(`compensation:item.min-quantity`)}
                      </TableCell>
                      <TableCell>
                        <AmountCO2 value={minQuantity} />
                      </TableCell>
                    </TableRow>
                  )}
                  {item.maxQuantity && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {t(`compensation:item.max-quantity`)}
                      </TableCell>
                      <TableCell>
                        <AmountCO2 value={item.maxQuantity} />
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t(`compensation:item.price`)}
                    </TableCell>
                    <TableCell>
                      <Price price={item.pricePerUnit} per={UNIT_CO2} />
                    </TableCell>
                  </TableRow>
                  {item.validMedia?.length && (
                    <TableRow>
                      <TableCell component="th" scope="row" id="media">
                        {t(`compensation:purchase.media`)}
                      </TableCell>
                      <TableCell>
                        <Select name="medium" labelId="media" displayEmpty fullWidth>
                          <MenuItem value={''}> {t(`compensation:medium.no-selection`)}</MenuItem>
                          {item.validMedia.map((media) => (
                            <MenuItem key={media} value={media}>
                              {t(`compensation:medium.${media}`)}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell component="th" scope="row" id="quantity">
                      {t(`compensation:purchase.quantity`)}
                    </TableCell>
                    <TableCell>
                      <NumberInputField
                        name="quantity"
                        fullWidth
                        InputProps={{
                          inputProps: { 'aria-labelledby': 'quantity' },
                          endAdornment: <InputAdornment position="end">{UNIT_CO2}</InputAdornment>,
                        }}
                        fieldProps={{
                          validate: (quantity) => {
                            if (!quantity) {
                              return t(`compensation:validation.required`);
                            }
                            if (minQuantity && quantity < minQuantity) {
                              return t(`compensation:validation.min-quantity`, {
                                replace: { min: numberFormatter.format(minQuantity) },
                              });
                            }
                            if (item.maxQuantity && quantity > item.maxQuantity) {
                              return t(`compensation:validation.max-quantity`, {
                                replace: { max: numberFormatter.format(item.maxQuantity) },
                              });
                            }
                            return null;
                          },
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t(`compensation:purchase.sum`)}
                    </TableCell>
                    <TableCell>
                      <FormSpy<FormData>
                        subscription={{ values: true }}
                        render={({ values: { quantity } }) => {
                          return quantity ? (
                            <Price
                              price={{
                                value: item.pricePerUnit.value * quantity,
                                currency: item.pricePerUnit.currency,
                              }}
                            />
                          ) : null;
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="td" scope="row" colSpan={2}>
                      <FormSpy<FormData>
                        subscription={{ values: true }}
                        render={({ values: { medium } }) => {
                          return (
                            <Checkboxes
                              name="writeTimeSeries"
                              data={{
                                label: hasCompensationMeasurement(data, medium ?? null)
                                  ? t('compensation:purchase.save-as-time-series')
                                  : t('compensation:purchase.save-as-time-series-new-datapoint'),
                                value: true,
                              }}
                              helperText={t('compensation:purchase.save-as-time-series-help')}
                            />
                          );
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <FormSpy<FormData>
                    subscription={{ values: true }}
                    render={({ values: { writeTimeSeries, weighted } }) => {
                      return writeTimeSeries ? (
                        <>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {t(`compensation:purchase.compensation-for-node`, {
                                replace: {
                                  nodeType: getUnitTypeName(data.unit.type.mainType),
                                },
                              })}
                            </TableCell>
                            <TableCell>{data.unit.name}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {t(`compensation:purchase.compensation-for-time-range`)}
                            </TableCell>
                            <TableCell className={classes.dateRange}>
                              {startTime.format(TIME_FORMAT)}
                              <ArrowForward classes={{ root: classes.arrowIcon }} />
                              {endTime.format(TIME_FORMAT)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row" id="weighted">
                              {t(`compensation:purchase.weighted`)}
                            </TableCell>
                            <TableCell scope="row">
                              <Select
                                name="weighted"
                                labelId="weighted"
                                fullWidth
                                fieldProps={{
                                  format: (value) => value.toString(),
                                  parse: (value) => 'true' === value,
                                }}
                              >
                                <MenuItem value={'false'}>{t(`compensation:purchase.weighted-equal`)}</MenuItem>
                                <MenuItem value={'true'}>{t(`compensation:purchase.weighted-by-data-point`)}</MenuItem>
                              </Select>
                            </TableCell>
                          </TableRow>
                          {weighted && (
                            <TableRow aria-labelledby="row-weighted-by-label">
                              <TableCell component="th" scope="row" id="row-weighted-by-label">
                                {t(`compensation:purchase.weighted-by`)}
                              </TableCell>
                              <TableCell>
                                <DataPointSelectionField
                                  name="weightedBy"
                                  skipAccounting
                                  skipManual
                                  fieldProps={{
                                    validate: requiredField,
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      ) : null;
                    }}
                  />
                </TableBody>
              </Table>
            </CardContent>

            <CardActions className={classes.controls}>
              <HasPermission check={'canOrderCompensation'} negate>
                <DialogContentText color="error">{t(`compensation:purchase.missing-permission`)}</DialogContentText>
              </HasPermission>
              <HasPermission check={'canOrderCompensation'}>
                <Button color="primary" variant="contained" onClick={handleSubmit} disabled={!valid}>
                  {t(`compensation:purchase.reserve-button`)}
                </Button>
              </HasPermission>
            </CardActions>
          </Card>
        )}
      />
      {confirmationProperties && (
        <CompensationPurchaseConfirmation {...confirmationProperties} onClose={() => setConfirmationProperties(null)} />
      )}
    </>
  );
};

export default CompensationItemDetails;
