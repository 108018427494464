import { useQuery } from '@apollo/client';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { UnitNameQuery, UnitNameQueryVariables } from 'global-graphql/__generated__/UnitNameQuery';
import { queries } from 'global-graphql/queries';
import { FC, MouseEventHandler, useRef } from 'react';
import { AdditionalLinkProps } from '../../types';
import BrokenLink from '../BrokenLink/BrokenLink';
import useIsVisible from './hooks/useIsVisible';

type Props = AdditionalLinkProps & {
  fallbackText?: string | null;
  nodeId: string;
  href?: string;
  onClick?: () => void;
};

const useStyles = makeStyles({
  link: { cursor: 'pointer' },
});

const ResolvedUnitNameLink: FC<Props> = ({
  nodeId,
  onClick: onClickDelegate,
  customLabel,
  fallbackText,
  href,
  beforeOpen,
}) => {
  const classes = useStyles();
  const linkRef = useRef(null);
  const isInViewport = useIsVisible(linkRef);
  const { data, error } = useQuery<UnitNameQuery, UnitNameQueryVariables>(queries.unitName, {
    variables: { id: nodeId },
    fetchPolicy: 'cache-first',
    skip: !isInViewport,
  });
  if (error) {
    return <BrokenLink text={customLabel || fallbackText || nodeId} />;
  }

  const onClick: MouseEventHandler<HTMLElement> | undefined = onClickDelegate
    ? (e) => {
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
        if (beforeOpen) {
          beforeOpen(e.currentTarget);
        }
        onClickDelegate();
      }
    : undefined;
  return (
    <>
      <Link className={classes.link} href={href} ref={linkRef} onClick={onClick} role="link">
        {customLabel || (data?.unit.name ?? nodeId)}
      </Link>
    </>
  );
};

export default ResolvedUnitNameLink;
