import { createModel } from '@rematch/core';
import { RootModel } from 'global-store/models';

export type ImportExportDialogState = {
  dialogState: boolean;
};

export const importExportDialog = createModel<RootModel>()({
  state: { dialogState: false } as ImportExportDialogState,
  reducers: {
    openImportExportScreen: (state) => ({ ...state, dialogState: true }),
    closeImportExportScreen: (state) => ({ ...state, dialogState: false }),
  },
});
