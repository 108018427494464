import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  link: {
    cursor: 'not-allowed',
  },
});

type Props = {
  text?: string | null;
};

const BrokenLink: FC<Props> = ({ text }) => {
  const classes = useStyles();
  const { t } = useTranslation('notification', { useSuspense: false });
  return (
    <Link color="error" className={classes.link} title={t('broken-link-help')} role="link" data-test-type="broken-link">
      {text || t('broken-link-fallback')}
    </Link>
  );
};

export default BrokenLink;
