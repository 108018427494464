import { useApolloClient } from '@apollo/client';
import { DataPointInput } from '@ence/apis-graphql';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import useGetUnitTypeName from 'global-hooks/useGetUnitTypeName';
import numberFormatter from 'global-utils/numberFormatter/numberFormatter';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompensationMedium } from '../../../../../../../__generated__/globalTypes';
import { GetCompensationItems_compensationItems } from '../../../../../graphql/__generated__/GetCompensationItems';
import {
  PurchaseCompensationItem,
  PurchaseCompensationItemVariables,
} from '../../../../../graphql/__generated__/PurchaseCompensationItem';
import { mutations, queries } from '../../../../../graphql/queries';
import { TIME_FORMAT, UNIT_CO2 } from '../../../../../utils/constants';
import Price from '../../Price/Price';

export type CompensationPurchaseConfirmationProps = {
  item: GetCompensationItems_compensationItems;
  startDate: moment.Moment;
  endDate: moment.Moment;
  quantity: number;
  writeTimeSeries: boolean;
  nodeId: string;
  medium: CompensationMedium | null;
  weightedBy: DataPointInput | null;
  newDatapoint: boolean;
  nodeTypeName: string;
  nodeName: string;
  token: string;
  onClose: () => void;
};

const CompensationPurchaseConfirmation: FC<CompensationPurchaseConfirmationProps> = ({
  quantity,
  item,
  onClose,
  writeTimeSeries,
  nodeId,
  nodeTypeName,
  nodeName,
  weightedBy,
  medium,
  startDate,
  endDate,
  token,
}) => {
  useGetUnitTypeName();
  const { t } = useTranslation(['compensation', 'global']);
  const [status, setStatus] = useState<'sending' | 'error' | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();
  const confirm = async () => {
    setStatus(null);
    const { data } = await client.mutate<PurchaseCompensationItem, PurchaseCompensationItemVariables>({
      mutation: mutations.purchaseCompensationItem,
      variables: {
        request: {
          compensationItem: item.id,
          currency: item.pricePerUnit.currency,
          pricePerUnit: item.pricePerUnit.value,
          medium,
          quantity,
          distribution: writeTimeSeries
            ? {
                start: startDate.format(),
                // end + 1 day since we want to have the values distributed over the whole last day as well
                end: endDate.format(),
                nodeId: nodeId,
                weightedBy,
              }
            : null,
          token,
        },
      },
      refetchQueries: [queries.getCompensationOrders],
    });
    const response = data?.purchaseCompensationItem;
    if (response?.__typename === 'CompensationOrder') {
      enqueueSnackbar(
        t(`compensation:confirmation`, {
          replace: {
            orderId: response.orderId,
            created: moment(response.created).format('DD.MM.YYYY HH:mm'),
          },
        }),
        { variant: 'success', persist: true, autoHideDuration: undefined }
      );
    } else {
      enqueueSnackbar(t(`compensation:creation-error`), { variant: 'error' });
    }
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="lg">
      <DialogTitle>{t(`compensation:summary.headline`)}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t(`compensation:summary.text`)}</DialogContentText>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t(`compensation:summary.quantity`)}</TableCell>
                <TableCell>{t(`compensation:summary.item-name`)}</TableCell>
                <TableCell>{t(`compensation:item.supplier`)}</TableCell>
                <TableCell>{t(`compensation:purchase.media`)}</TableCell>
                <TableCell>{t(`compensation:summary.unit-price`)}</TableCell>
                <TableCell>{t(`compensation:summary.total-price`)}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{numberFormatter.format(quantity)}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.supplier.name}</TableCell>
                <TableCell>{medium && t(`compensation:medium.${medium}`)}</TableCell>
                <TableCell>
                  <Price price={item.pricePerUnit} per={UNIT_CO2} />
                </TableCell>
                <TableCell>
                  <Price
                    price={{
                      value: item.pricePerUnit.value * quantity,
                      currency: item.pricePerUnit.currency,
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <DialogContentText>
          {writeTimeSeries
            ? t(`compensation:summary.write-time-series`, { replace: { nodeTypeName, nodeName, nodeId } }) +
              ' ' +
              (weightedBy
                ? t(`compensation:summary.write-time-distributed-weighted`, {
                    replace: {
                      start: startDate.format(TIME_FORMAT),
                      end: endDate.format(TIME_FORMAT),
                    },
                  })
                : t(`compensation:summary.write-time-distributed-equal`, {
                    replace: {
                      start: startDate.format(TIME_FORMAT),
                      end: endDate.format(TIME_FORMAT),
                    },
                  }))
            : t(`compensation:summary.write-no-time-series`)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="default" disabled={status === 'sending'}>
          {t(`global:confirmation-button-cancel`)}
        </Button>
        <Button onClick={confirm} color="primary" variant="contained" disabled={status === 'sending'}>
          {t(`compensation:summary.confirm`)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompensationPurchaseConfirmation;
