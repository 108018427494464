import { Replacements_CompensationDetailsLink } from 'global-graphql/__generated__/Replacements';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { AdditionalLinkProps } from '../../types';
import BrokenLink from '../BrokenLink/BrokenLink';
import ResolvedUnitNameLink from '../ResolvedUnitNameLink/ResolvedUnitNameLink';

type Props = Replacements_CompensationDetailsLink & AdditionalLinkProps;

const CompensationDetailsLink: FC<Props> = ({
  compensationNode,
  startDate,
  endDate,
  fallbackText,
  ...additionalLinkProps
}) => {
  const history = useHistory();
  if (!compensationNode) {
    return <BrokenLink text={additionalLinkProps.customLabel || fallbackText} />;
  }
  const path =
    startDate && endDate
      ? `/compensation/${compensationNode.id}/from/${startDate}/to/${endDate}`
      : `/measurements/${compensationNode.id}`;

  const open = () => history.push(path);

  return (
    <ResolvedUnitNameLink
      {...additionalLinkProps}
      fallbackText={fallbackText}
      onClick={open}
      nodeId={compensationNode.id}
      href={path}
    />
  );
};

export default CompensationDetailsLink;
