import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GetCompensationItems_compensationItems } from '../../../../graphql/__generated__/GetCompensationItems';
import { UNIT_CO2 } from '../../../../utils/constants';
import Price from '../Price/Price';
import ValidMedia from '../ValidMedia/ValidMedia';

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    cursor: 'pointer',
    '&$selected, &$selected:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  selected: {},
}));

type Props = {
  items: GetCompensationItems_compensationItems[];
  setSelectedItem: (item: GetCompensationItems_compensationItems) => void;
  selectedItem?: string;
};
const CompensationItemTable: FC<Props> = ({ items, setSelectedItem, selectedItem }) => {
  const classes = useStyles();
  const { t } = useTranslation('compensation');
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t(`compensation:item.name`)}</TableCell>
            <TableCell>{t(`compensation:item.supplier`)}</TableCell>
            <TableCell>{t(`compensation:item.price`)}</TableCell>
            <TableCell>{t(`compensation:item.valid-media`)}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <TableRow
              key={item.id}
              hover
              selected={item.id === selectedItem}
              classes={{
                selected: classes.selected,
                root: classes.row,
              }}
              onClick={() => setSelectedItem(item)}
              aria-labelledby={`row-${index}-label`}
            >
              <TableCell component="th" scope="row" id={`row-${index}-label`}>
                {item.name}
              </TableCell>
              <TableCell>{item.supplier.name}</TableCell>
              <TableCell>
                <Price price={item.pricePerUnit} per={UNIT_CO2} />
              </TableCell>
              <TableCell>
                <ValidMedia media={item.validMedia} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CompensationItemTable;
