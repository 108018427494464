import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField';
import { numberLocale } from 'global-utils/numberLocale/numberLocale';
import { ShowErrorFunc, showErrorOnChange } from 'mui-rff';
import { FC } from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import NumberFormat from 'react-number-format';

type TextFieldProps = Partial<Omit<MuiTextFieldProps, 'onChange'>> & {
  name: string;
  fieldProps?: Partial<FieldProps<any, any>>;
  showError?: ShowErrorFunc;
};

const NumberInputField: FC<TextFieldProps> = ({ name, fieldProps, hidden, ...rest }) => (
  <Field
    name={name}
    render={({ input, meta }) =>
      hidden ? null : <NumberFormatWrapper input={input} meta={meta} name={name} {...rest} />
    }
    {...fieldProps}
  />
);

type TextWrapperProps = FieldRenderProps<string>;

const NumberFormatWrapper: FC<TextWrapperProps> = ({
  input: { name, value, type, onChange, onBlur, onFocus, ...restInput },
  meta,
  required,
  fullWidth = true,
  helperText,
  showError = showErrorOnChange,
  ...rest
}) => {
  const { error, submitError } = meta;
  const isError = showError({ meta });

  return (
    <NumberFormat
      isNumericString
      customInput={MuiTextField}
      onValueChange={(values) => onChange(values.floatValue)}
      thousandSeparator={numberLocale.thousandSeparator}
      decimalSeparator={numberLocale.decimalSeparator}
      fullWidth={fullWidth}
      helperText={isError ? error || submitError : helperText}
      error={isError}
      onBlur={onBlur}
      onFocus={onFocus}
      name={name}
      value={value}
      required={required}
      inputProps={{ required, ...restInput }}
      {...rest}
    />
  );
};

export default NumberInputField;
