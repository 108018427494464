import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'global-utils/uiThemeConfig';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(),
  },
}));

export const UnknownWidget: FunctionComponent = () => {
  const { t } = useTranslation('widgets');
  const classes = useStyles();

  return <span className={classes.container}>{t('widgets:unknown-widget')}</span>;
};

export default UnknownWidget;
