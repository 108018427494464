import { useApolloClient, useQuery } from '@apollo/client';
import { CsvData } from '@connect-enlight/widget-plugin-api/api/renderer-api';
import Renderer from '@connect-enlight/widget-plugin-api/react/runtime/renderer';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Renderers } from 'global-graphql/__generated__/Renderers';
import { WidgetLazyLoad, WidgetLazyLoadVariables } from 'global-graphql/__generated__/WidgetLazyLoad';
import { queries } from 'global-graphql/queries';
import useProcessResultHandler from 'global-hooks/useProcessResultHandler';
import useWidgetComputeResult from 'global-hooks/useWidgetComputeResult';
import useWidgetRetrieval from 'global-hooks/useWidgetRetrieval/useWidgetRetrieval';
import uiThemeConfig from 'global-utils/uiThemeConfig';
import { CSSProperties, FunctionComponent, memo, useCallback, useMemo } from 'react';
import '../../../index.css';
import ErrorBoundary from '../../containers/ErrorBoundary/ErrorBoundary';
import ErrorInDataFetcher from './components/ErrorInDataFetcher/ErrorInDataFetcher';
import ErrorInRenderer from './components/ErrorInRenderer/ErrorInRenderer';
import UnknownRenderer from './components/UnknownRenderer/UnknownRenderer';
import UnknownWidget from './components/UnknownWidget/UnknownWidget';

export interface Props {
  widgetId: string;
  config: Record<string, any> | null;
  onConfigurationChange?: (value: any) => void | Promise<void>;
  onScreenStateChanged?: (fullscreen: boolean) => void;
  onCsvExportTriggerChanged?: (newTrigger: (() => Promise<CsvData | null>) | null) => void;
  height?: number | string;
  width?: number | string;
  style?: CSSProperties;
  emphasiseErrors?: boolean;
  showErrorInSnackbar?: boolean;
  'data-test-type'?: string;
  wrapper?: (child: JSX.Element, renderData: unknown) => JSX.Element;
}

const useStyles = makeStyles({
  reloading: {
    position: 'absolute',
    width: '100%',
  },
  initialLoading: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  widget: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
  },
  reloadingWidget: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
});

const NO_OP_CALLBACK = () => {};

export const Widget: FunctionComponent<Props> = ({
  widgetId,
  height,
  width,
  style,
  config,
  onConfigurationChange = NO_OP_CALLBACK,
  onScreenStateChanged,
  onCsvExportTriggerChanged,
  emphasiseErrors,
  showErrorInSnackbar,
  wrapper,
  'data-test-type': dataTestType,
}) => {
  const classes = useStyles();
  const widget = useWidgetRetrieval(widgetId);
  const processResultHandler = useProcessResultHandler({ showErrorInSnackbar: true });
  const { data: rendererData, loading: loadingRenderer } = useQuery<Renderers>(queries.renderers);
  const {
    error,
    loading: loadingWidgetConfig,
    result: widgetComputeResult,
    processingError,
  } = useWidgetComputeResult<any>({
    widgetId,
    showErrorInSnackbar,
    config,
    skip: !widget || !rendererData,
  });
  const client = useApolloClient();
  const fetchData = useCallback(
    async (request?: any) => {
      let lazyResponseData;
      let lazyError;
      try {
        const lazyResult = await client.query<WidgetLazyLoad, WidgetLazyLoadVariables>({
          query: queries.widgetLazyLoad,
          variables: {
            widgetId,
            request: JSON.stringify(request ?? null),
          },
        });
        lazyResponseData = lazyResult.data;
        lazyError = lazyResult.error;
      } catch (e) {
        lazyError = e;
      }
      return processResultHandler(lazyResponseData?.widgetLazyLoad, lazyError).result;
    },
    [client, widgetId, processResultHandler]
  );
  const css = useMemo(() => ({ ...style, height, width }), [style, height, width]);

  if (processingError) return showErrorInSnackbar ? null : processingError;
  if (error) return showErrorInSnackbar ? null : <ErrorInDataFetcher emphasise={emphasiseErrors} error={error} />;

  if (loadingRenderer || !widget) return null;

  if (widgetId === '' || !widget.id) return <UnknownWidget />;

  const renderer = rendererData?.renderer?.find((value) => value.id === widget.renderer);
  if (!renderer) return <UnknownRenderer />;

  if (widgetComputeResult === undefined)
    return (
      <div style={css} className={classes.initialLoading} data-test-type="initial-loading-indicator">
        <CircularProgress />
      </div>
    );

  const { protocol, host } = window.location;
  const WidgetComponent = (
    <Renderer
      title={renderer.name}
      entryPoint={'/widget-service' + renderer.entryPoint}
      config={widgetComputeResult}
      onSelectionChanged={onConfigurationChange}
      onScreenStateChanged={onScreenStateChanged}
      onCsvExportTriggerChanged={onCsvExportTriggerChanged}
      theme={uiThemeConfig as any}
      locale={'de'}
      fetchData={fetchData}
      stylesheets={[`${protocol}//${host}/assets/font-definition.css`]}
    />
  );
  const comp = (
    <div
      style={css}
      className={classes.widget + (loadingWidgetConfig ? ` ${classes.reloadingWidget}` : '')}
      data-test-type={dataTestType || widgetId}
    >
      {loadingWidgetConfig && (
        <div className={classes.reloading}>
          <LinearProgress />
        </div>
      )}
      {WidgetComponent}
    </div>
  );
  return (
    <ErrorBoundary<{ widgetId: string; config: Record<string, any> | null }>
      ErrorFallback={ErrorInRenderer}
      fallbackProps={{ widgetId, config }}
    >
      {wrapper ? wrapper(comp, widgetComputeResult) : comp}
    </ErrorBoundary>
  );
};

export default memo(Widget);
