import { createModel } from '@rematch/core';
import { RootModel } from 'global-store/models';

export interface ReportsListModel {
  highlightedReportSource: string | null;
}

export const reportsList = createModel<RootModel>()({
  state: { highlightedReportSource: null } as ReportsListModel,
  reducers: {
    highlightReportSource: (state, { reportSource }) => ({ ...state, highlightedReportSource: reportSource }),
  },
});

export default reportsList;
