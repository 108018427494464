import { Dispatch, StoreState } from 'global-store/store';
import { History, Location } from 'history';
import { ComponentType, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

export type UpdateReduxConfig = { location: Location; dispatch: Dispatch };

export type UpdateUrlConfig<ExtractedStoreState> = {
  state: ExtractedStoreState;
  history: History;
  location: Location;
};

export type Config<ExtractedStoreState> = {
  updateReduxOnMount: (config: UpdateReduxConfig) => void;
  extractStateFromRedux: (storeState: StoreState) => ExtractedStoreState;
  updateURLOnReduxChange: (config: UpdateUrlConfig<ExtractedStoreState>) => void;
};

const savePopupScreenStateInURL = function<ExtractedStoreState>(config: Config<ExtractedStoreState>) {
  const { updateReduxOnMount, extractStateFromRedux, updateURLOnReduxChange } = config;

  return function<Props>(WrappedComponent: ComponentType<Props>): ComponentType<Props> {
    return (props) => {
      const location = useLocation();
      const history = useHistory();
      const dispatch = useDispatch<Dispatch>();
      const state = useSelector(extractStateFromRedux);
      const hasMountedRef = useRef(false);

      useEffect(() => {
        if (!hasMountedRef.current) {
          updateReduxOnMount({ location, dispatch });

          hasMountedRef.current = true;
        } else {
          updateURLOnReduxChange({ state, history, location });
        }
      }, [state, history, location, hasMountedRef, dispatch]);

      return <WrappedComponent {...props} />;
    };
  };
};

export default savePopupScreenStateInURL;
