import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import NotificationImportant from '@material-ui/icons/NotificationImportant';
import ActionsPopover, {
  Props as ActionsPopoverProps,
} from 'global-components/presentational/ActionsPopover/ActionsPopover';
import { FunctionComponent } from 'react';
import NotificationPopup from './components/NotificationPopup/NotificationPopup';
import useNotificationsPolling from './hooks/useNotificationsPolling/useNotificationsPolling';

export interface Props {}

const popoverContent: ActionsPopoverProps['popoverContent'] = ({ closeActionsContent }) => (
  <NotificationPopup closeNotificationList={closeActionsContent} />
);

export const Notifications: FunctionComponent<Props> = () => {
  const { length: numberOfNotifications } = useNotificationsPolling();

  const iconRenderContainer: ActionsPopoverProps['iconRenderContainer'] = (props) => (
    <IconButton {...props} data-test-type="open-notifications-list-button">
      {numberOfNotifications > 0 ? (
        <Badge
          badgeContent={<span data-test-type="number-of-notifications-badge">{numberOfNotifications}</span>}
          color="primary"
        >
          {props.children}
        </Badge>
      ) : (
        props.children
      )}
    </IconButton>
  );

  return (
    <ActionsPopover
      iconRender={NotificationImportant}
      iconRenderContainer={iconRenderContainer}
      popoverContent={popoverContent}
    />
  );
};

export default Notifications;
