import { Replacements_ReportsLink } from 'global-graphql/__generated__/Replacements';
import { Dispatch } from 'global-store/store';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AdditionalLinkProps } from '../../types';
import BrokenLink from '../BrokenLink/BrokenLink';
import ReportNameLink from './components/ReportNameLink/ReportNameLink';

type Props = Replacements_ReportsLink & AdditionalLinkProps;

const ReportsLink: FC<Props> = ({ fallbackText, reportNode, reportTemplate, ...additionalLinkProps }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();

  if (!reportTemplate) {
    return <BrokenLink text={additionalLinkProps.customLabel || fallbackText} />;
  }

  const open = () => {
    if (!location.pathname.startsWith('/reports')) {
      history.push(`/reports`);
    }
    if (reportNode) {
      dispatch.reportsList.highlightReportSource({ reportSource: reportNode.id });
    }
  };

  return (
    <ReportNameLink
      {...additionalLinkProps}
      fallbackText={fallbackText}
      onClick={open}
      reportTemplateId={reportTemplate._id}
    />
  );
};

export default ReportsLink;
