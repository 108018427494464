import { Dispatch, StoreState } from 'global-store/store';
import popupUrlConfigWithId from 'global-utils/popupUrlConfigWithId/popupUrlConfigWithId';
import savePopupScreenStateInURL from 'global-utils/savePopupScreenStateInURL/savePopupScreenStateInURL';

const openScreen = (dispatch: Dispatch, companyId: string) =>
  dispatch.usersDialog.handleUserActionsDialogState({
    dialogState: true,
    companyId,
  });

const getState = ({ usersDialog }: StoreState) => ({ id: usersDialog.companyId, open: usersDialog.dialogState });

export default savePopupScreenStateInURL(popupUrlConfigWithId('users', openScreen, getState));
